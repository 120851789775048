import React, { useState, useEffect } from "react";
import { FaShoppingCart } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './navHeader.css';

const ShoppingCartIcon = () => {
    const [usuario, setUsuario] = useState(JSON.parse(localStorage.getItem('usuario')) || {});

    useEffect(() => {
        const usuarioGuardado = JSON.parse(localStorage.getItem('usuario'));
        setUsuario(usuarioGuardado || {});
    }, []); // Solo se ejecuta una vez al montar el componente para obtener el usuario guardado

    // Establecer la ruta del carrito basado en el tipo de usuario
    const carritoPath = usuario.tipo === 'cliente' ? '/client/pedido' : '/login';

    return (
        <Link to={carritoPath}>
            <div className="carrito">
                <FaShoppingCart size={30} />
            </div>
        </Link>
    );
}

export default ShoppingCartIcon;
