import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../../Components/shared/header/header.jsx';
import HeaderAdmin from '../../Components/Admin/headerAdmin.jsx';
import HeaderClient from '../../Components/client/headerClient/headerClient.jsx';
import PieDePagina from '../../Components/shared/piePagina.jsx';



const LayoutConEncabezado = ({ children }) => {
  const location = useLocation();
  let encabezado;
  let pieDePagina;

 
  if (location.pathname.startsWith('/admin')) {
    encabezado = <HeaderAdmin />;
    pieDePagina = <PieDePagina />;
  } else if (location.pathname.startsWith('/client')) {
    encabezado = <HeaderClient />;
    pieDePagina = <PieDePagina />;
  } else {
    encabezado = <Header />;
    pieDePagina = <PieDePagina />;
  }

  return (
    <>
      {encabezado}
      <div>
        {children}
        {pieDePagina}
      </div>
    </>
  );
};

export default LayoutConEncabezado;
