import React, { useState, useEffect } from 'react';
import { FaUser, FaEnvelope, FaPhone, FaMapMarkedAlt, FaChevronDown, FaChevronUp, FaEdit, FaSave, FaLock ,} from 'react-icons/fa';
import './perfil.css'
import Swal from "sweetalert2";

const SeccionDesplegable = ({ titulo, children }) => {
  const [abierto, setAbierto] = useState(false);


  return (
    <div className='seccion'>
      <div  className='tituloSeccion' onClick={() => setAbierto(!abierto)}>
        {titulo}
        {abierto ? <FaChevronUp /> : <FaChevronDown />}
      </div>
      {abierto && <div className='menuDesplegable'>{children}</div>}
    </div>
  );
};

const PerfilCliente = () => {
  const [usuarios, setUsuarios] = useState(JSON.parse(localStorage.getItem('usuario')) || {});
  const [editNombre, setEditNombre] = useState(false);
  const [nombre, setNombre] = useState('');
  const [editUsername, setEditarUsername] = useState(false);
  const [username, setUsername] = useState('');
  const [editApellidoP, setEditApellidoP] = useState(false);
  const [apellidoP, setApellidoP] = useState('');
  const [editApellidoM, setEdiltApellidoM] = useState(false);
  const [apellidoM, setApellidoM] = useState('');
  const [editCorreo, setEditCorreo] = useState(false);
  const [correo, setCorreo] = useState('');
  const [editTelefono, setEditTelefono] = useState(false);
  const [telefono, setTelefono] = useState('');
  const [editCalle, setEditCalle] = useState(false);
  const [calle, setCalle] = useState('');
  const [editLocalidad, setEditLocalidad] = useState(false);
  const [localidad, setLocalidad] = useState('');
  const [editMunicipio, setEditMunicipio] = useState(false);
  const [municipio, setMunicipio] = useState('');
  const [editEstado, setEditEstado] = useState(false);
  const [estado, setEstado] = useState('');
  const [editCodigoPostal, setEditCodigoPostal] = useState(false);
  const [codigoPostal, setCodigoPostal] = useState('');
  const [editPais, setEditPais] = useState(false);
  const [pais, setPais] = useState('');
  const [editDetalle, setEditDetalle] = useState(false);
  const [detalle, setDetalle] = useState('');
  const [contrasenaActual, setContrasenaActual] = useState('');
  const [nuevaContrasena, setNuevaContrasena] = useState('');
  const [confirmarNuevaContrasena, setConfirmarNuevaContrasena] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordShown, setPasswordShown]= useState(false);
  const [usuarios1, setUsuarios1] = useState([]);

  useEffect(() => {
    if (usuarios) {
      setNombre(usuarios.nombre || '');
      setApellidoP(usuarios.apellidoP || '');
      setApellidoM(usuarios.apellidoM || '');
      setCorreo(usuarios.correo || '');
      setTelefono(usuarios.telefono || '');
      setUsername(usuarios.username || '');

      const direccionUsuario = usuarios.direccion || {};
      setCalle(direccionUsuario.calle || '');
      setLocalidad(direccionUsuario.localidad || '');
      setMunicipio(direccionUsuario.municipio || '');
      setEstado(direccionUsuario.estado || '');
      setPais(direccionUsuario.pais || '');
      setCodigoPostal(direccionUsuario.codigo_postal || '');
      setDetalle(direccionUsuario.detalles || '');
    }
  }, [usuarios]);

  const handleNombreClienteChange = (e) => {
    setNombre(e.target.value);
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleApellidoPaternoChange = (e) => {
    setApellidoP(e.target.value);
  };

  const handleApellidoMaternoChange = (e) => {
    setApellidoM(e.target.value);
  };

  const handleCorreoChange = (e) => {
    setCorreo(e.target.value);
  };

  const handleTelefonoChange = (e) => {
    setTelefono(e.target.value);
  };

  const handleCalleChange = (e) => {
    setCalle(e.target.value);
  };

  const handleLocalidadChange = (e) => {
    setLocalidad(e.target.value);
  };

  const handleMunicipioChange = (e) => {
    setMunicipio(e.target.value);
  };

  const handleEstadoChange = (e) => {
    setEstado(e.target.value);
  };

  const handleCodigoChange = (e) => {
    setCodigoPostal(e.target.value);
  };

  const handlePaisChange = (e) => {
    setPais(e.target.value);
  };

  const handleDetalleChange = (e) => {
    setDetalle(e.target.value);
  };
  const handleContrasenaActualChange = (e) => {
    setContrasenaActual(e.target.value);
  };

  const handleNuevaContrasenaChange = (e) => {
    setNuevaContrasena(e.target.value);
  };

  const handleConfirmarNuevaContrasenaChange = (e) => {
    setConfirmarNuevaContrasena(e.target.value);
  };
     // Función para alternar la visibilidad de la contraseña
     const togglePasswordVisibility=()=>{
      setPasswordShown(!passwordShown);
    };
  
    const validarFormatoContrasena = () => {
      const formatoValido = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(nuevaContrasena);
      return formatoValido;
    };
 



  const actualizarContrasena = async () => {
    if (nuevaContrasena !== confirmarNuevaContrasena) {
      setErrorMessage('La nueva contraseña y la confirmación no coinciden.');
      return;
    }
    if (!validarFormatoContrasena()) {
      setErrorMessage('La contraseña debe tener al menos 8 caracteres, incluyendo al menos una letra mayúscula, una letra minúscula, un número y un carácter especial.');
      return;
    }


    const url = `https://ehr-mall-server.onrender.com/api/usuarios/${usuarios._id}/cambiar-contrasena`;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          contrasenaActual: contrasenaActual,
          nuevaContrasena: nuevaContrasena,
        }),
      });

      if (response.ok) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Se cambio la contraseña correctamente",
          showConfirmButton: false,
          timer: 1500
        });

        setContrasenaActual('');
        setNuevaContrasena('');
        setConfirmarNuevaContrasena('');
      } else {
        const data = await response.json();
        // setErrorMessage(data.mensaje || 'Error al actualizar la contraseña.');
      }
    } catch (error) {
      // console.error('Error al procesar la solicitud:', error);
      setErrorMessage('Error de red.');
     
    }
  };
  
  // Consultar usuarios al cargar el componente
  useEffect(() => {
    cargarUsuarios();
  }, []);


   // Función para cargar usuarios
   const cargarUsuarios = () => {
    fetch('https://ehr-mall-server.onrender.com/api/usuarios')
      .then(response => response.json())
      .then(data => setUsuarios1(data))
      .catch(error => console.error("Error al cargar los usuarios", error));
  };





  const guardarInformacion = async (fieldToUpdate, valueToUpdate) => {
   
   
    try {
      // Obtener la lista actualizada de usuarios antes de verificar
    const usuarios1 = await fetch('https://ehr-mall-server.onrender.com/api/usuarios').then(response => response.json());

    // Verificar si el campo a actualizar es 'username' o 'correo'
    if (fieldToUpdate === 'username' || fieldToUpdate === 'correo') {
      const encontrado = usuarios1.find(usuario => usuario[fieldToUpdate] === valueToUpdate);
      if (encontrado) {
        // Mostrar mensaje de error si el valor ya existe
        const errorMessageText = fieldToUpdate === 'username' ? 'El usuario ya existe.' : 'El correo electrónico ya está registrado.';
        setErrorMessage(errorMessageText);
        return;
      }
    }

    const url = `https://ehr-mall-server.onrender.com/api/usuarios/${usuarios._id}`;
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ [fieldToUpdate]: valueToUpdate }),
      });

      if (response.ok) {
        const usuarioActualizado = { ...usuarios, [fieldToUpdate]: valueToUpdate };
        localStorage.setItem('usuario', JSON.stringify(usuarioActualizado));
        setUsuarios(usuarioActualizado);
        // Reset edit state for the field
        switch (fieldToUpdate) {
          case 'nombre':
            setEditNombre(false);
            break;
          case 'apellidoP':
            setEditApellidoP(false);
            break;
          case 'apellidoM':
            setEdiltApellidoM(false);
            break;
          case 'username':
            setEditarUsername(false);
            break;
          case 'correo':
            setEditCorreo(false);
            break;
          case 'telefono':
            setEditTelefono(false);
            break;
          
          default:
            break;
        }
      } else {
        console.error(`Error al guardar ${fieldToUpdate}`);
      }
    } catch (error) {
      console.error('Error al procesar la solicitud:', error);
    }
  };



  const guardarDireccion = async () => {
    const urlBase = 'https://ehr-mall-server.onrender.com';
    const idUsuario = usuarios._id;
    const url = `${urlBase}/api/usuarios/${idUsuario}`;
  
    try {
      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          direccion: {
            calle: calle,
            localidad: localidad,
            municipio: municipio,
            estado: estado,
            pais: pais,
            codigo_postal: codigoPostal,
            detalles: detalle,
          },
        }),
      });
  
      if (response.ok) {
        // Actualizar la dirección del usuario en el estado local
        const usuarioActualizado = {
          ...usuarios,
          direccion: {
            calle: calle,
            localidad: localidad,
            municipio: municipio,
            estado: estado,
            pais: pais,
            codigo_postal: codigoPostal,
            detalles: detalle,
          },
        };
  
        // Actualizar el usuario en el almacenamiento local
        localStorage.setItem('usuario', JSON.stringify(usuarioActualizado));
        setUsuarios(usuarioActualizado);
  
        // Cerrar la sección de edición
        setEditCalle(false);
        setEditLocalidad(false);
        setEditMunicipio(false);
        setEditEstado(false);
        setEditPais(false);
        setEditCodigoPostal(false);
        setEditDetalle(false);
  
        // Mostrar un mensaje de alerta
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "¡La dirección se ha actualizado correctamente!",
            showConfirmButton: false,
            timer: 1500
          });
  
      } else {
        console.error('Error al guardar la dirección:', response.statusText);
        alert('Hubo un error al guardar la dirección. Inténtalo de nuevo.');
      }
    } catch (error) {
      console.error('Error al procesar la solicitud:', error);
      setErrorMessage('Hubo un error al procesar la solicitud. Inténtalo de nuevo.');
    }
  };
  


  return (
    <div className='loginForm'> 
      <div class="head-form" autoComplete="off">
      <h1>Perfil de Usuario</h1>
      <img src={require("../../img/Logos/inconoEhrMall.png")} alt="Logo"  className="logo"/>
      </div>
      {usuarios && (
        <div >

          <div className="avatarContainer">
            {editUsername ? (
              <div>
                <input type="text" class="form-input-1"   value={username} onChange={(e) => setUsername(e.target.value)} />
                <button className='button' onClick={() => guardarInformacion('username', username)}><FaSave /></button>
              </div>
            ) : (
              <p className='nombreUsuario'><FaUser /> {usuarios.username} <button onClick={() => setEditarUsername(true)}><FaEdit /></button></p>
            )}
          </div>

          <SeccionDesplegable titulo="Información Personal">
            <div className="contenidoSeccion">
              {editNombre ? (
                <div >
                  <label className="estiloEtiqueta">Nombre:</label>
                  <input   class="form-input-1"   type="text" value={nombre} onChange={(e) => setNombre(e.target.value)} />
                  <button  onClick={() => guardarInformacion('nombre', nombre)} className='button'><FaSave /></button>
                </div>

              ) : (
                <p><FaUser /> <strong>Nombre:</strong> {usuarios.nombre || ' '} <button onClick={() => setEditNombre(true)}  className='buttonEdit'><FaEdit /></button></p>
              )}
              {editApellidoP ? (
                <div className="estiloCampo">
                  <label className="estiloEtiqueta" >Apellido Paterno:</label>
                  <input class="form-input-1"   type="text"  value={apellidoP} onChange={handleApellidoPaternoChange}  />
                  <button onClick={() => guardarInformacion('apellidoP', apellidoP)} className='button'><FaSave /></button>
                </div>
              ) : (
                <p> <FaUser /><strong>Apellido Paterno:</strong> {usuarios.apellidoP || ''} <button onClick={() => setEditApellidoP(true)} className='buttonEdit' ><FaEdit /></button></p>
              )}
              {editApellidoM ? (
                <div className="estiloCampo">
                  <label  className="estiloEtiqueta" >Apellido Materno:</label>
                  <input class="form-input-1"   type="text" value={apellidoM} onChange={handleApellidoMaternoChange}  />
                  <button onClick={() => guardarInformacion('apellidoM', apellidoM)}  className='button'><FaSave /></button>
                </div>
              ) : (
                <p> <FaUser /><strong>Apellido Materno:</strong> {usuarios.apellidoM || ''} <button onClick={() => setEdiltApellidoM(true)} className='buttonEdit'  ><FaEdit /></button></p>
              )}
            </div>
          </SeccionDesplegable>

          <SeccionDesplegable titulo="Contactos">
            <div className='contenidoSeccion' >
              {editCorreo ? (
                <div className='estiloCampo'>
                  <label className="estiloEtiqueta">Correo electrónico:</label>
                  <input class="form-input-1"   type="email" value={correo} onChange={handleCorreoChange}  />
                  <button onClick={() => guardarInformacion('correo', correo)}><FaSave /></button>
                </div>
              ) : (
                <p><FaEnvelope /> <strong>Correo electrónico:</strong> {usuarios.correo || ''} <button onClick={() => setEditCorreo(true)} ><FaEdit /></button></p>
              )}
              {editTelefono ? (
                <div>
                  <label className="estiloEtiqueta">Teléfono:</label>
                  <input class="form-input-1"   type="tel" value={telefono} onChange={handleTelefonoChange}  />
                  <button onClick={() => guardarInformacion('telefono', telefono)}><FaSave /></button>
                </div>
              ) : (
                <p><FaPhone /> <strong>Teléfono:</strong> {usuarios.telefono || ''} <button onClick={() => setEditTelefono(true)} ><FaEdit /></button></p>
              )}
            </div>
          </SeccionDesplegable>

          <SeccionDesplegable titulo="Dirección">
        <div className='contenidoSeccion'>
          {editCalle || editLocalidad || editMunicipio || editEstado || editPais || editCodigoPostal || editDetalle ? (
            <>
              <div className='estiloCampo'>
                <label className="estiloEtiqueta">Calle:</label>
                <input class="form-input-1"   type="text" value={calle} onChange={handleCalleChange} />
              </div>
              <div className='estiloCampo'>
                <label className="estiloEtiqueta">Localidad:</label>
                <input class="form-input-1"   type="text" value={localidad} onChange={handleLocalidadChange} />
              </div>
              <div className='estiloCampo'>
                <label className="estiloEtiqueta">Municipio:</label>
                <input class="form-input-1"   type="text" value={municipio} onChange={handleMunicipioChange} />
              </div>
              <div className='estiloCampo'>
                <label className="estiloEtiqueta">Estado:</label>
                <input type="text" class="form-input-1"  value={estado} onChange={handleEstadoChange} />
              </div>
              <div className='estiloCampo'>
                <label className="estiloEtiqueta">País:</label>
                <input class="form-input-1"   type="text" value={pais} onChange={handlePaisChange} />
              </div>
              <div className='estiloCampo'>
                <label className="estiloEtiqueta">Código Postal:</label>
                <input class="form-input-1"   type="text" value={codigoPostal} onChange={handleCodigoChange} />
              </div>
              <div className='estiloCampo'>
                <label className="estiloEtiqueta">Detalles:</label>
                <input class="form-input-1"   type="text" value={detalle} onChange={handleDetalleChange} />
              </div>
              <br></br><br></br>
              <center><button className='button' onClick={guardarDireccion} ><FaSave /></button></center>
            </>
          ) : (
            <>
              <p><FaMapMarkedAlt /><strong>Calle:</strong> {usuarios.direccion.calle || ' '} <button onClick={() => setEditCalle(true)}><FaEdit /></button></p>
              <p><FaMapMarkedAlt /><strong>Localidad:</strong> {usuarios.direccion.localidad || 'No especificado'} <button onClick={() => setEditLocalidad(true)}><FaEdit /></button></p>
              <p><FaMapMarkedAlt /><strong>Municipio:</strong> {usuarios.direccion.municipio || 'No especificado'} <button onClick={() => setEditMunicipio(true)}><FaEdit /></button></p>
              <p><FaMapMarkedAlt /><strong>Estado:</strong> {usuarios.direccion.estado || 'No especificado'} <button onClick={() => setEditEstado(true)}><FaEdit /></button></p>
              <p><FaMapMarkedAlt /><strong>País:</strong> {usuarios.direccion.pais || 'No especificado'} <button onClick={() => setEditPais(true)}><FaEdit /></button></p>
              <p><FaMapMarkedAlt /><strong>Código Postal:</strong> {usuarios.direccion.codigo_postal || 'No especificado'}   <button onClick={() => setEditCodigoPostal(true)}><FaEdit /></button></p>
              <p><FaMapMarkedAlt /><strong>Detalles:</strong> {usuarios.direccion.detalles || 'No especificado'} <button onClick={() => setEditDetalle(true)}><FaEdit /></button></p>
            </>
          )}
        </div>
      </SeccionDesplegable>
      <SeccionDesplegable titulo="Cambiar Contraseña">
            <div >
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <FaLock style={{ marginRight: '10px' }} />
                <div style={{ flex: 1 }}>
                  <label className="estiloEtiqueta">Contraseña actual:</label>
                  <input
                    type="password"
                    value={contrasenaActual}
                    onChange={handleContrasenaActualChange}
                    class="form-input-1" 
                    required
                   
                  />
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <FaLock style={{ marginRight: '10px' }} />
                <div style={{ flex: 1 }}>
      <label className="estiloEtiqueta">Nueva contraseña:</label>
      <div className="password-input-container">
        <input
          autoComplete="off"
          type={passwordShown ? 'text' : 'password'}
          name="password"
          id="pwd"
          value={nuevaContrasena}
          onChange={handleNuevaContrasenaChange}
          className="form-input-1"
          pattern=" ^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
          title="La contraseña debe tener al menos 8 caracteres, incluyendo al menos una letra mayúscula, una letra minúscula, un número y un carácter especial."
          minLength={8}
          required
          placeholder="Ingrese una contraseña segura"
        />
        <span onClick={togglePasswordVisibility} className="password-toggle-icon">
          <i className={`fa ${passwordShown ? 'fa-eye-slash' : 'fa-eye'}`} aria-hidden="true"></i>
        </span>
      </div>
    </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <FaLock style={{ marginRight: '10px' }} /> {/* Cambia este ícono según prefieras */}
                <div style={{ flex: 1 }}>
                  <label className="estiloEtiqueta">Confirmar nueva contrasena:</label>
                  <input
                    type="password"
                    value={confirmarNuevaContrasena}
                    onChange={handleConfirmarNuevaContrasenaChange}
                    class="form-input-1" 
                   
                  />
        
                </div>
              </div>
              <button onClick={actualizarContrasena} className='buttonActualizar'>
                Actualizar Contraseña
              </button>
            </div>
          </SeccionDesplegable>
                    {/* Mostrar mensaje de error */}
                    {errorMessage && (
                <p style={{ color: "red" }}>{errorMessage}</p>
        )}
        </div>
      )}
    </div>
  );
};

export default PerfilCliente;


