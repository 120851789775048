

import React, { useState, useEffect } from 'react';
import { Layout, Menu, Typography } from 'antd';
import {
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import './fooder.css'

const { Footer } = Layout;
const { Text } = Typography;

const PieDePaginaCliente = () => {


  return (
<Layout className='fooder'>
    <div style={{ marginTop: '1px', backgroundColor: '#582c83', textAlign: 'center', padding: '30px 20px', maxWidth: '100%', boxSizing: 'border-box' }}>
        <Text style={{ backgroundColor: '', color: '#ffffff', fontSize: '18px' }}>&copy; {new Date().getFullYear()} ®EHR MALL. Todos los derechos reservados.</Text>
    </div>
</Layout>
  );
};

export default PieDePaginaCliente;