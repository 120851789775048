import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import {NavHeader} from "../shared/cabezeraNav/navHeader";
import { Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList,faListUl,faUser,faTag,faBuilding,faShoppingCart, faDoorOpen } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';




import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../shared/header/header.css';
import './headerAdmin.css'

const { SubMenu } = Menu;

const HeaderAdmin = () => {
    const navigate = useNavigate();
    

    //este sirve par acerra sesion
    const handleLogout=()=>{
        console.log("Cerrando sesion....");
        localStorage.removeItem('usuario');
         navigate('/');

    }
    
  
    return (
        <div>
            <NavHeader to='/admin/perfilAdmin'/>
            <header className="header">
                <div className="container">
                    <ul className="menu d-flex justify-content-center align-items-center mb-0">
                        <Menu  mode="horizontal">
                            <SubMenu key="sub0" 
                            title={
                                <span className="menu-item-text">
                                        <FontAwesomeIcon icon={faUser} style={{ color: 'black', marginRight: '8px' }} />
                                        Usuarios
                                    </span>
                            
                            }>
                                <Menu.Item key="1">
                                    <Link to="/admin/usuarios" className="custom-link">
                                    Lista Usuarios
                                    </Link>
                                    </Menu.Item>
                                <Menu.Item key="2">
                                    <Link to="/admin/insertarUsuarios" className="custom-link">
                                    Insertar Usuarios
                                    </Link>
                                    </Menu.Item>
                            </SubMenu>

                            <SubMenu key="sub1" 
                            title={
                                <span className="menu-item-text">
                                        <FontAwesomeIcon icon={faListUl} style={{ color: 'black', marginRight: '8px' }} />
                                        Productos
                                    </span>
                            
                            }>
                                <Menu.Item key="3">
                                    <Link to="/admin/listaProductos" className="custom-link">
                                    Lista de productos
                                    </Link>
                                    </Menu.Item>
                                    
                                <Menu.Item key="4">
                                <Link to="/admin/insertarProductos" className="custom-link">
                                    Insertar Productos
                                    </Link>
                                    
                                    </Menu.Item>
                            </SubMenu>

                            <SubMenu key="sub2" 
                            title={
                                <span className="menu-item-text">
                                        <FontAwesomeIcon icon={faTag} style={{ color: 'black', marginRight: '8px' }} />
                                        Categorias
                                    </span>
                            
                            }>
                                <Menu.Item key="5">
                                <Link to="/admin/categoria" className="custom-link">
                                    Lista de categorias
                                    </Link>
                                    </Menu.Item>
                                <Menu.Item key="6">
                                <Link to="/admin/insertarCategoria" className="custom-link">
                                    Insertar Categorias
                                    </Link>
                                    </Menu.Item>
                            </SubMenu>

                            <SubMenu key="sub3" 
                            title={
                                <span className="menu-item-text">
                                        <FontAwesomeIcon icon={faBuilding} style={{ color: 'black', marginRight: '8px' }} />
                                         Informacion de Empresa
                                    </span>
                            
                            }>
                                <SubMenu key="sub3-1" title="Quienes Somos">
                                    <Menu.Item key="7">
                                    <Link to="/admin/quienesSomos" className="custom-link">
                                        Lista quienes somos
                                        </Link>
                                        </Menu.Item>
                                        
                                </SubMenu>
                                <SubMenu key="sub2-2" title="Mision y Vision">
                                    <Menu.Item key="8">
                                    <Link to="/admin/misionVision" className="custom-link">
                                        Lista Mision y Vision
                                        </Link>
                                        </Menu.Item>
                                </SubMenu>
                                <SubMenu key="sub2-3" title="Politicas y provacidad">
                                    <Menu.Item key="9">
                                    <Link to="/admin/politicas" className="custom-link">
                                        Lista Politicas y Privacidad
                                        </Link>
                                        </Menu.Item>
                                       
                                </SubMenu>
                                


                            </SubMenu>
                            <SubMenu key="sub4" 
                            title={
                                <span className="menu-item-text">
                                        <FontAwesomeIcon icon={faShoppingCart} style={{ color: 'black', marginRight: '8px' }} />
                                        Pedidos
                                    </span>
                            
                            }>
                                
                               
                                    <Menu.Item key="10">
                                    <Link to="/admin/ejemplo2" className="custom-link">
                                        Lista de Pedidos
                                        </Link>
                                        </Menu.Item>
                              
                                
                            </SubMenu>
                            <Menu.Item key="11"  style={{ color: 'black', marginRight: '8px', fontSize:16 }}
                            icon={<FontAwesomeIcon icon={faDoorOpen} />} onClick={handleLogout}>
                               Cerrar Sesión
                            </Menu.Item>
                           




                        </Menu>
                        
                        
                       

                        

                       
                    </ul>
                </div>
            </header>
        </div>
    );
}

export default HeaderAdmin;
