import React, { useState, useEffect } from 'react';
import './listaProductos.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { useParams, useNavigate } from 'react-router-dom';



const ListaProductos = ({ products }) => {
  const navigate = useNavigate();
  const [usuarios] = useState(JSON.parse(localStorage.getItem('usuario')) || {});


  const handleProductClick = (productoId) => {
    const usuarioTipo = usuarios.tipo;
    if(usuarioTipo==='cliente'){
      navigate( `/client/detalles/${productoId}`)
      return;

    } 
    else{
      navigate(`/detalles/${productoId}`);
      return;
    }
  };
    







  return (
    <>
    <div className="containers">
      {products.map((producto) => (
        <div className="card" key={producto._id}  onClick={() => handleProductClick(producto._id)} >
          <div className="card-img">
            {/* Usamos producto.imagenes[0] para obtener la URL de la primera imagen del producto */}
            <img src={producto.imagenes[0]} alt={producto.nombre} className='logos' /> 
          </div>
          <div className="card-title">{producto.nombre}</div>
         
          <p className="card-divider" />
          <div className="card-footer">
            <div className="card-price">
              <span>$</span> {producto.precio}
            </div>
            <button className="card-btn">
              <FontAwesomeIcon icon={faShoppingCart} className="cart-icon" size={10}/>
             
            </button>
          </div>
        </div>
      ))}
    {/*Vamos con la paginacion*/}
    
    </div>
    </>
  );
};


export default ListaProductos;
