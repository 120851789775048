import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CustomTable from '../Usuarios/tablaUsuarios';
import { useLocation } from 'react-router-dom';

const ProductosPorCategoria = () => {
  const location = useLocation();
  

  return (
    <div>
      <h2>Current Path: {location.pathname}</h2>
      <p>Query Parameters: {location.search}</p>
      <p>Hash Fragment: {location.hash}</p>
      <p>Hash Fragment: {location.state}</p>
    </div>
  );
};

export default ProductosPorCategoria;
