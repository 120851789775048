import React from 'react';
import { Table } from 'antd';
import './tblComponet.css'

const CustomTable = ({ data, columns, tableSize }) => {
  return (
    <Table
      className='tblComponet'
      dataSource={data}
      columns={columns}
      pagination={{pageSize:"5"}} // Desactivar paginación si no se necesita
      size={tableSize} // Tamaño de la tabla: 'small', 'middle' o 'large'
      style={{ width: '100%' }} // Ajusta el ancho de la tabla al 100% del contenedor
      
    />
  );
}


export default CustomTable;
