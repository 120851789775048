import React from "react";
import { FaUser } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './navHeader.css';

const Login = ({to}) => {
  return (
    <Link to={to}>
      <div className="login">
        <FaUser size={30} />
      </div>
    </Link>
  );
};


export {Login};
