
import React from "react";
import './navHeader.css';
import SearchBar from "./busqueda";
import ShoppingCart from "./carrito"
import  {Login} from "./inconoLogin";

const NavHeader= ({to }) =>{

    return(
        //NAVEADER
        <div className="nav1">
            <img src={require("../../img/Logos/inconoEhrMall.png")} alt="Logo"  className="logo"/>
            <SearchBar/>
            <ShoppingCart/>
            <Login to={to} />
        </div>
    )
}


export  {NavHeader};