import React, { useEffect, useState } from 'react';
import './login.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import bcrypt from 'bcryptjs';
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { classNames } from 'primereact/utils';





const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [usuarios, setUsuarios] = useState([]);
  //Funcion para mostrar contraseña o ocultar
  const [passwordShown, setPasswordShown]= useState(false);

  //funcion que hace las consultas de los usuarios
  useEffect(()=>{
    axios.get('https://ehr-mall-server.onrender.com/api/usuarios')
      .then(response => {
        // Almacenar los datos de los usuarios en el estado
        setUsuarios(response.data);
      })
      .catch(error => console.error("Error al cargar los usuarios: ", error));
  }, []);

   // Función para alternar la visibilidad de la contraseña
    const togglePasswordVisibility=()=>{
      setPasswordShown(!passwordShown);
    };
  

  //funcion de logueo
  const handleSubmit = async(e)=>{
    e.preventDefault();
    
    //comparamos si los campos no estan vacios
    if(!username.trim() && !password.trim()){
      setErrorMessage("Campos vacios");
      return;
    }
    else if(!username.trim){
      setErrorMessage("Campo usuario vacio");
      return;
    }else if(!password.trim()){
      setErrorMessage("Campo contraseña vacio")
      return;
    }else{

      // Buscar al usuario por el nombre de usuario
      const usuarioEncontrado = usuarios.find(usuario => usuario.username.trim() === username.trim());

      if(usuarioEncontrado){
       // Comparar la contraseña ingresada con la contraseña almacenada usando bcrypt
      const isMatch = await bcrypt.compare(password, usuarioEncontrado.password);

        if(isMatch){
        // Guardar la información del usuario en el almacenamiento local
        localStorage.setItem('usuario', JSON.stringify(usuarioEncontrado));

          
          let ruta='/';
          let mensaje = 'Has iniciado sesión correctamente.';
          switch(usuarioEncontrado.tipo)
          {
            case "cliente":
              ruta = '/client';
              break;
            case "administrador":
              ruta = '/admin';
              break;
            default:
              setErrorMessage('Tipo de usuario desconocido');
              return;
          }
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: mensaje,
            showConfirmButton: false,
            timer: 1500
          }).then(()=>{
            window.location.href=ruta;

          });
        }else{
          setErrorMessage('Contraseña incorrecta');
        }
      }else{
        setErrorMessage('Usuario incorrecto')
      }
    }
  }


  return (
    <>

  <div class="overlay-1">
       <form className='loginForm' onSubmit={handleSubmit}>
    <div class="con">
          <header class="head-form">
            <h2>Login</h2>
             <p>Bienvenido A EHR MALL</p>
             <img src={require("../../img/Logos/inconoEhrMall.png")} alt="Logo"  className="logo"/>
          </header>
       <div class="field-set">
         <span class="input-item-1">
         <i class="fa fa-user"></i> 
         </span>
         <input 
         class="form-input" 
         id="txt-input" 
         type="text" 
         placeholder="Inserte su usuario"
         maxLength={20}
         minLength={4}
         value={username}
         onChange={(e)=>setUsername(e.target.value)}
         required />
         <br/>
         <span class="input-item-1">
         <i class="fa fa-key"></i>
         </span>
         <input 
         class="form-input" 
         type={passwordShown ? 'text' : 'password'}
         placeholder="Inserte su contraseña" 
         id="pwd"  name="password" 
         maxLength={30}
         minLength={6}
         value={password}
         onChange={(e)=> setPassword(e.target.value)}
         required />
         
        <span onClick={ togglePasswordVisibility} style={{ cursor: 'pointer' }}>
        {passwordShown ? (
          <i className="fa fa-eye" aria-hidden="true" id='eye'></i>
        ) : (
          <i className="fa fa-eye" aria-hidden="true" id='eye'></i>
        )}
      </span>
         <br/>
         <center>
         {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
         <br></br>
        <button class="log-in">Inciar sesion</button></center>
       </div>
        <div class="other">
         <Link to=""><button class="btn submits frgt-pass">olvide la contraseña</button></Link>
         <Link to="/registro"><button class="btn submits sign-up">Registrar
         <i class="fa fa-user-plus" aria-hidden="true"></i>
         </button> </Link>
       </div>
   </div>
  

</form>
</div>
    
    
    
    
    </>
    
  );
};


export default Login; 