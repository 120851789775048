import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import {NavHeader} from "../../shared/cabezeraNav/navHeader";
import { Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList ,faDoorOpen, faBars} from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../shared/header/header.css';
import { useNavigate } from 'react-router-dom';



const { SubMenu } = Menu;

const HeaderClient = () => {
    
    const [categorias, setCategorias] = useState([]);
    const navigate = useNavigate();
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

   
    
  
    
      useEffect(() => {
        // Realizar la consulta de categorías al cargar el componente
        fetch('https://ehr-mall-server.onrender.com/api/categorias')
          .then(response => response.json())
          .then(data => setCategorias(data))
          .catch(error => console.error('Error fetching categorias:', error));
      }, []);
    const handleMenuClick = (e) => {
        // Manejar el click en el menú aquí si es necesario
        console.log('Menu item clicked:', e);
    };

    const toggleSubMenu = () => {
        // Toggle para mostrar/ocultar el submenú
        // Puedes implementar la lógica según tus necesidades
    };

    //este sirve par acerra sesion
    const handleLogout=()=>{
        console.log("Cerrando sesion....");
        localStorage.removeItem('usuario');
         navigate('/');
      

    }



    return (
        <div>
            <NavHeader to="/client/perfilClient"  toC="/client/pedido" />
            <header className="header">
                <div className="container">
                    <ul className={`menu d-flex justify-content-center align-items-center mb-0  `}>
                        <li className="text-light mx-3">
                            <Link to="/client/">
                                <i className="fas fa-home"></i> Inicio
                            </Link>
                        </li>
                        <Menu
                            mode="horizontal"              
                            defaultSelectedKeys={['1']}
                            onClick={handleMenuClick}
                        >
                            <SubMenu
                                key="sub1"
                                title={
                                    <span className="menu-item-text">
                                        <FontAwesomeIcon icon={faList} style={{  marginRight: '8px' }} />
                                        Categoría
                                    </span>
                                }
                                onMouseEnter={toggleSubMenu}
                                onMouseLeave={toggleSubMenu}
                                className="submenu-text" // Clase de estilo para el texto del submenú
                            >
                                {categorias.map((categoria) => (
                                    <Menu.Item key={categoria._id}>
                                        <Link to={`/client/categorias/${categoria._id}`} className="menu-item-link">
                                            {categoria.categoria}
                                        </Link>
                                    </Menu.Item>
                                ))}
                            </SubMenu>
                            <Menu.Item key="11"  style={{  marginRight: '8px' }}
                            icon={<FontAwesomeIcon icon={faDoorOpen} />} onClick={handleLogout}>
                               Cerrar Sesión
                            </Menu.Item>
                        </Menu>
                        <li className="text-light mx-3">
                            <Link to="/client/pedido">
                                <i className="fas fa-shopping-cart"></i> Pedido
                            </Link>
                        </li>
                    </ul>
                    </div>
            </header>
        </div>
    );
}

export default HeaderClient;
