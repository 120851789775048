// Dentro del componente ColorList
import React from 'react';

const TallaList = ({ tallas, selectedTalla, onTallaChange }) => {

    const hadleTallaChange=(talla)=>{
        onTallaChange(talla);
    }
    

    return (
        <div className='listaColores'>
            <h2 className='detalleColor'>Talla:</h2>
            {tallas.map((talla, index) => {
                // Verificar que Talla sea una cadena de texto válida
                if (typeof talla !== 'string') {
                    console.warn(`La talla en la posición ${index} no es una cadena válida:`, talla);
                    return null; // O manejar de otra forma si Talla no es válido
                }
 

                return (
                    <div className='forma' key={index}>

                        <div className='forma'>

                        <input
                            className='inputColor'
                            type="text"
                            key={index}
                            value={talla}
                            style={{
                                border: selectedTalla === talla ? '2px solid black' : '1px solid #ccc',
                                padding: '5px',
                                cursor: 'pointer',
                              }}
                            onClick={() => hadleTallaChange(talla)}
                           
                           
                           
                            
                        />
                        </div>
                    </div>
                );

            })}
        </div>
    );
};

export default TallaList;
