import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons'; // Importamos el icono de retroceso
import '../../public//inicio/listaProductos.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import Avatar from './Subcategoria';


const ProductosFiltradosPublico = () => {
  const { categoria } = useParams();
  const navigate = useNavigate();
  const [productos, setProductos] = useState([]);
  const [paginaActual, setPaginaActual] = useState(1);
  const [loading, setLoading] = useState(true);
  const [usuarios, setUsuarios] = useState(JSON.parse(localStorage.getItem('usuario')) || {});
  const [totalPaginas, setTotalPaginas] = useState(0); // Estado para el total de páginas
  const [subCategoria, setSubCategoria] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://ehr-mall-server.onrender.com/api/productos/categorias/${categoria}?page=${paginaActual}&limit=4`);
        if (!response.ok) {
          throw new Error('Error fetching productos');
        }
        const data = await response.json();
        setProductos(data.productos);

        setTotalPaginas(data.totalPaginas);
        setLoading(false); // Marca la carga como completa

        // Extraer y almacenar los nombres de las subcategorías en un arreglo
        const subcategorias = data.productos.map(productos => productos.subCategoria);
        const nombresUnicos= subcategorias.filter((subcategorias, index , array) => {
          return array.indexOf(subcategorias) === index;
        })
        setSubCategoria(nombresUnicos);

      } catch (error) {
        console.error('Error fetching productos:', error);
        setLoading(false); // Marca la carga como completa incluso en caso de error
      }
    };
    fetchData();
  }, [categoria, paginaActual]);
 

  const handleProductClick = (productoId) => {
    const path = usuarios.tipo==='cliente'? `/client/categorias/${categoria}/${productoId}` : `/categorias/${categoria}/${productoId}`;
    navigate(path);
  };

 //Este es para lo subCategorias
const handleSubCategoriaClick = (subcategoria) => {
  // Construir la ruta base dependiendo del tipo de usuario
  const basePath = usuarios.tipo === 'cliente' ? '/client/subCategoria/' : '/subCategoria/';

  // Navegar a la ruta base y pasar el parámetro subcategoria como estado
  navigate(`${basePath}${subcategoria}`, { state: { subcategoria } });
}




  // Genera los números de página
  const paginas = [];
  for (let i = 1; i <= totalPaginas; i++) {
    paginas.push(i);
  }
 
  return (
    <>
      {loading ? (
        <>
          <div class="pyramid-loader">
            <div class="wrapper">
              <span class="side side1"></span>
              <span class="side side2"></span>
              <span class="side side3"></span>
              <span class="side side4"></span>
              <span class="shadow"></span>
            </div>
          </div>
        </>
      ) : (
        <>
          {/* Aqui vamos a poner lo submenus*/}
          <div className='subMenu'>

          <div className='example-2'>
          
            {subCategoria.map((subCategorias, index) => (
             <Avatar key={index} tooltipText={subCategorias} onClick={() => handleSubCategoriaClick(subCategorias)} />
            ))}
          </div>
          </div>

          <div className="containers">
            {productos.map((producto) => (
              <div
                className="card"
                key={producto._id}
                onClick={() => handleProductClick(producto._id)}
              >
                <div className="card-img">
                  {/* Usamos producto.imagenes[0] para obtener la URL de la primera imagen del producto */}
                  <img
                    src={producto.imagenes[0]}
                    alt={producto.nombre}
                    className="logos"
                  />
                </div>
                <div className="card-title">{producto.nombre}</div>
                <div className="card-subtitle">{producto.descripcion}</div>
                <p className="card-divider" />
                <div className="card-footer">
                  <div className="card-price">
                    <span>$</span> {producto.precio}
                  </div>
                  <button className="card-btn">
                    <FontAwesomeIcon
                      icon={faShoppingCart}
                      className="cart-icon"
                      size={10}
                    />
                  </button>
                </div>
              </div>
            ))}
            {/*Vamos con la paginacion*/}
          </div>
        </>
      )}

      {/* Renderizamos la paginación */}
      <div className="pagination">
        {paginas.map((numero) => (
          <button
            key={numero}
            onClick={() => setPaginaActual(numero)}
            style={{
              background: paginaActual === numero ? "purple" : "#ddd",
              color: paginaActual === numero ? "white" : "black",
            }}
            className="page-number"
          >
            {numero}
          </button>
        ))}
      </div>
    </>
  );
};

export default ProductosFiltradosPublico;


