import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import {NavHeader} from "../cabezeraNav/navHeader";
import { Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { faMapMarkerAlt,faUser, faUtensils ,faSearch,faBars,faHome } from '@fortawesome/free-solid-svg-icons';

import './header.css';
import Password from "antd/es/input/Password";

const { SubMenu } = Menu;

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [categorias, setCategorias] = useState([]);
   
    
    useEffect(() => {
        // Realizar la consulta de categorías al cargar el componente
        fetch('https://ehr-mall-server.onrender.com/api/categorias')
            .then(response => response.json())
            .then(data => setCategorias(data))
            .catch(error => console.error('Error fetching categorias:', error));


    }, []);

    const handleMenuClick = (e) => {
        // Manejar el click en el menú aquí si es necesario
        console.log('Menu item clicked:', e);
    };

    const toggleSubMenu = () => {
        // Toggle para mostrar/ocultar el submenú
        // Puedes implementar la lógica según tus necesidades
    };

    //Funconones para el menu desplegable
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
      };
      
      

    return (
        <>
        <div>
            <NavHeader to="/login"/>
            <header className="header">
                <div className="container"> 
                    <ul className="menu d-flex justify-content-center align-items-center mb-0">
                        <li className="text-light mx-3 A">
                            <Link to="/">
                                <i className="fas fa-home"></i> Inicio
                            </Link>
                        </li>
                        <div className="hamburger-menu" onClick={toggleMenu}>
                      <i className="fas fa-bars"></i>
                         </div> 

                        <Menu
                            className="cmbCategoria A "
                            mode="horizontal"
                            defaultSelectedKeys={['1']}
                            onClick={handleMenuClick}
                            // style={{ backgroundColor: '#327dbb' }}
                        >
                            <SubMenu
                                key="sub1"
                                title={
                                    <span className="menu-item-text A">
                                        <FontAwesomeIcon icon={faList} style={{ marginRight: '8px' }} />
                                        Categoría
                                    </span>
                                }
                                onMouseEnter={toggleSubMenu}
                                onMouseLeave={toggleSubMenu}
                                className="submenu-text A" // Clase de estilo para el texto del submenú
                            >
                                {categorias.map((categoria) => (
                                    <Menu.Item key={categoria._id}>
                                        <Link to={`/categorias/${categoria._id}`} className="menu-item-link">
                                            {categoria.categoria}
                                        </Link>
                                    </Menu.Item>
                                ))}
                            </SubMenu>
                        </Menu>

        
                    </ul>
                </div>
            </header>
           

        </div>
  
        <div className={`side-menu ${isMenuOpen ? 'open' : ''}`}>
        <div className="side-menu-header">
          <div className="close-menu" onClick={toggleMenu}>
            <i className="fas fa-times"></i>
          </div>
          <div className='menuDezplegable'>
          EHR MALL
          </div> 
          <div className="logo">
          <img
              src={require("../../img/Logos/inconoEhrMall.png")}
              alt="Logo"
              className="logo"
            />
          </div>
          
        </div>
        <ul className="InconR-1">
            <div className="location">
            <i className="fas fa-map-marker-alt"></i>
            <span>Ciudad de México, CDMX, Mexico</span>
            <i className="fas fa-caret-down"></i>
            </div>
         </ul>

        <div className="side-menu-content">
        <Link to="/login" >
          <button className="btn-2">Ingresar</button>
        </Link>

          <button className="btn-2">Registro</button>
          <ul className="navbar-nav  mb-lg-0 inicio-menuDezplegable">
                <li className="nav-item">
                <Link to="/" className="nav-link titulomenu">
                    <FontAwesomeIcon
                      icon={faHome}
                      className="inconosHeader-inicio"
                    />{" "}
                    Inicio
                  </Link>
                </li>
              </ul>
          <div className="sections">
            <h3>Secciones</h3>
          </div>
          <div className="others">
            <ul>
              <li><a href="#registra-restaurante"> <FontAwesomeIcon icon={faUtensils} /> Registra tu restaurante</a></li>
            </ul>
          </div>
        
        </div>
      </div>
      {isMenuOpen && <div className="overlay" onClick={toggleMenu}></div>}


        
        


        </>
    );
}

export default Header;
