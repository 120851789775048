import React from 'react';
import './boton.css'
import Swal from "sweetalert2";


const BotonV = ({ onClick, children }) => {
  return (
    <button onClick={onClick} className="mi-botonV">
      {children}
    </button>
  );
};

const BotonR = ({ onClick, children, title }) => {
  const handleEliminar = () => {
    Swal.fire({
      title: `¿Estás seguro de que deseas eliminar este ${title}?`,
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
      customClass: {
        container: 'my-swal-container',
        title: 'my-swal-title',
        content: 'my-swal-content',
        confirmButton: 'my-swal-confirm-button', // Clase personalizada para el botón de confirmación
        cancelButton: 'my-swal-cancel-button'    // Clase personalizada para el botón de cancelación
      },
      icon: 'question',  // Tipo de ícono de advertencia para la confirmación de eliminación
      showLoaderOnConfirm: true,  // Mostrar un loader antes de confirmar la eliminación
      preConfirm: () => onClick(),  // Llamar a la función onClick si se confirma la eliminación
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Eliminado!",
          text: "El "+title+ "ha sido eliminado correctamente.",
          icon: "success",
          customClass: {
            container: 'my-swal-container',
            title: 'my-swal-title',
            content: 'my-swal-content'
          }
        });
      } else {
        Swal.fire({
          title: "Cancelado",
          text: "No se ha realizado la eliminación.",
          icon: "error",
          customClass: {
            container: 'my-swal-container',
            title: 'my-swal-title',
            content: 'my-swal-content'
          }
        });
      }
    });
  };
  
    return (
      <button onClick={handleEliminar} className="mi-botonR">
        {children}
      </button>
    );
  };


  //BOTON GUARADAR Y CALCELAR
const BtnGuardar = ({ onClick, children }) => {
  return (
    <button type="submit" className="form-button primary-button" onClick={onClick}>
      {children}
    </button>
  );
};

// Componente de botón secundario
const BtnCancelar = ({ onClick, children, title }) => {
  const handleCancelar = () => {
    Swal.fire({
      text: `Cancelado editar ${title}`, // Usar template literals para insertar la variable 'title'
      icon: "error"
    });
  };

  const handleClick = () => {
    if (onClick) { // Verifica si onClick está definido y no es 'null' ni 'undefined'
      handleCancelar(); // Llama a la función handleCancelar si se hace clic en el botón
      onClick(); // Llama a la función onClick pasada como prop
    }
  };

  return (
    <button type="button" className="form-button secondary-button" onClick={handleClick}>
      {children}
    </button>
  );
};

export {BotonR,BotonV,BtnCancelar,BtnGuardar} 
