
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import {BotonR,BotonV,BtnCancelar,BtnGuardar}  from '../Usuarios/boton';
import CustomTable from '../Usuarios/tablaUsuarios';
import { Link } from "react-router-dom";
import { SearchOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2';
import "./products.css";



const ListaProductos=()=>{
    const navigate= useNavigate();
    const [productoss, setProductos]=useState([]);
    const [productoEditar, setProductoEditar]= useState(null);
    const [categorias, setCategorias] = useState([]);
    
    //Consultar Los productos 
    useEffect(()=>{
      //Hacemos consulta de categorias
      const fetchCategorias = async () => {
        try {
          const response = await axios.get('https://ehr-mall-server.onrender.com/api/categorias');
          setCategorias(response.data);
        } catch (error) {
          console.error("Error al cargar las categorías", error);
        }
      };
        fetchCategorias();
        cargarProductos();
    },[])
     
    //Funcion para cargar Los porductos
    const cargarProductos= async()=>{
        try{
            const response= await axios.get('https://ehr-mall-server.onrender.com/api/productos');
            setProductos(response.data);

        }catch(error){
            console.error("Error al cargar los productos", error);
        }
    };



    const eliminarProductos = async (id)=>{
        try{
            await axios.delete(`https://ehr-mall-server.onrender.com/api/productos/${id}`);
            cargarProductos();
        }catch(error){
            console.error("Error al eliminar el producto", error);
        }
    };

    //Mostramos el formulario
    const mostrarFormularioEdicion= (producto)=>{
        setProductoEditar(producto);
    };

    const editarProducto = async (e)=>{
        e.preventDefault();
        //Aseguramos de que la url y el objeto enviado sean correctas
        const url=`https://ehr-mall-server.onrender.com/api/productos/${productoEditar._id}`;
        const datosActualizados={
            nombre: productoEditar.nombre,
            descripcion: productoEditar.descripcion,
            precio: productoEditar.precio,
            subCategoria:productoEditar.subCategoria,
            stock: productoEditar.stock,
            color: productoEditar.color,
            talla: productoEditar.talla,
            descuento: productoEditar.descuento,
            categoriaP: productoEditar.caategoriaP,
            tiempoEntrega: productoEditar.tiempoEntrega,
            valoracion: productoEditar.valoracion,
        };
        try{
            const response = await axios.patch(url, datosActualizados);
            if(response.status===200){
              Swal.fire({
                title: 'Guardado',
                text: 'Los cambios se han guardado correctamente.',
                icon: 'success',
                customClass: {
                  container: 'my-swal-container',
                  title: 'my-swal-title',
                  content: 'my-swal-content'
                }
              })
                
                cargarProductos();
                setProductoEditar(null); //cerra el formualrio
            }
        }
        catch(error){
            console.error("Error a actualizar el producto");
            alert("Error actualizar")
        }
        cargarProductos();
    };

    const handleInputChange = (event)=>{
        const {name, value}=event.target;
        setProductoEditar((prevState)=>({
            ...prevState,
            [name]: value,
        }));
    }


      
    return (
        <>
       <div>
          {categorias.map(categoria=>{
            const productosEnCategoria = productoss.filter(producto=>producto.categoriaP===categoria._id);
            
            //Diseño de la tabla
        const columns = [
          { title: 'Nombre', dataIndex: 'nombre', key: 'nombre' },
          { title: 'Descripcion       ', dataIndex: 'descripcion', key: 'descripcion',align:'center' },
          { title: 'Precio', dataIndex: 'precio', key: 'precio' },
          { title: 'SubCategoria', dataIndex: 'subCategoria', key: 'subCategoria' },
          { title: 'Stock', dataIndex: 'stock', key: 'stock' ,align:'center', sortable:'ascedente'   },
          {
            title: 'Color',
            dataIndex: 'color',
            key: 'color',
            render: (colors) => colors.join(', '), // Renderiza los colores como una cadena separada por comas
        },
        {
            title: 'Talla',
            dataIndex: 'talla',
            key: 'talla',
            render: (tallas) => tallas.join(', '), // Renderiza las tallas como una cadena separada por comas
        },
          { title: 'Descuento', dataIndex: 'descuento', key: 'descuento' },
          { title: 'TiempoEntrega', dataIndex: 'descuento', key: 'descuento' },
          { title: 'Valoracion', dataIndex: 'valoracion', key: 'valoracion' },
          {
            title: 'Acciones',
            key: 'acciones',
            className:"titulo-tbl-1 ",
            render: (productos) => (
              <div>             
                <BotonV title={"Producto"} onClick={() => mostrarFormularioEdicion(productos)}>Editar</BotonV>
                <BotonR  title={"Producto"} onClick={() => eliminarProductos(productos._id)}> Eliminar</BotonR>
           </div>
            ),
          },
        ];
        return(
        <center><div className="floatbox-2">
        <div className="item-2" key={categoria._id}>
          <div className="content">
            <div className="Titulo">
              <h5 className='titulo-2'>Categoria: {categoria.categoria}</h5>
            </div>
            <p className='contador'>Total de Productos: {productosEnCategoria.length}</p>
          <center> <CustomTable data={productosEnCategoria} columns={columns} tableSize="large-xx" />  
          </center> 
          </div>
        </div>
        </div></center>   
          
        )
         
         })};
         {productoEditar && (
           <div className="form-container">
           <div className="title">
            <h5>Editar Usuario</h5>
          </div>
          <form onSubmit={editarProducto} className="my-swal-content">
            <div className="mb-3">
              <label htmlFor="nombre" className="form-label">Nombre:</label>
              <input type="text" className="form-input" id="nombre" name="nombre" value={productoEditar.nombre} onChange={handleInputChange} />
            </div>
            <div className="mb-3">
              <label htmlFor="descripcion" className="form-label">Descripcion:</label>
              <textarea type="text" className="form-input" id="descripcion" name="descripcion" value={productoEditar.descripcion} onChange={handleInputChange} />
            </div>
            <div className="mb-3">
              <label htmlFor="precio" className="form-label">Precio:</label>
              <input type="number" className="form-input" id="precio" name="precio" value={productoEditar.precio} onChange={handleInputChange} />
            </div>
            <div className="mb-3">
              <label htmlFor="subCategoria" className="form-label">subCategoria:</label>
              <input type="text" className="form-input" id="subCategoria" name="subCategoria" value={productoEditar.subCategoria} onChange={handleInputChange} />
            </div>
            <div className="mb-3">
              <label htmlFor="stock" className="form-label">Stock:</label>
              <input type="number" className="form-input" id="stock" name="stock" value={productoEditar.stock} onChange={handleInputChange} />
            </div>
            <div className="mb-3">
              <label htmlFor="color" className="form-label">Color:</label>
              <input type="text" className="form-input" id="color" name="color" value={productoEditar.color} onChange={handleInputChange} />
            </div>
            <div className="mb-3">
              <label htmlFor="talla" className="form-label">Talla:</label>
              <input type="text" className="form-input" id="talla" name="talla" value={productoEditar.talla} onChange={handleInputChange} />
            </div>
            <div className="mb-3">
              <label htmlFor="descuento" className="form-label">Descuento:</label>
              <input type="text" className="form-input" id="descuento" name="descuento" value={productoEditar.descuento} onChange={handleInputChange} />
            </div>
            <div className="mb-3">
              <label htmlFor="tiempoEntrega" className="form-label">TiempoEntrega:</label>
              <input type="text" className="form-input" id="tiempoEntrega" name="tiempoEntrega" value={productoEditar.tiempoEntrega} onChange={handleInputChange} />
            </div>
            <div className="mb-3">
              <label htmlFor="valoracion" className="form-label">Valoracion:</label>
              <input type="text" className="form-input" id="valoracion" name="valoracion" value={productoEditar.valoracion} onChange={handleInputChange} />
            </div>
            <BtnGuardar >Guardar Cambios</BtnGuardar>
            <BtnCancelar title={"Producto"} onClick={() => setProductoEditar(null)}>Cancelar</BtnCancelar>
           
          </form>
          
        </div>
                 
      )}
        





       </div>


        
        </>
    )
}


const InsertarProductos = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    nombre: '',
    descripcion: '',
    precio: '',
    subCategoria: '',
    stock: '',
    imagenes: [],
    color: [],
    talla: [],
    descuento: '',
    categoriaP: '',
    tiempoEntrega: '',
    valoracion: '',  
  });

  const [imageFiles, setImageFiles] = useState([]);
  const [section, setSection] = useState(0);
  const [categoriasP, setCategoriasP] = useState([]);

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const fieldName = name.split('.')[0];

    if (fieldName === 'color' || fieldName === 'talla') {
      setFormData((prevData) => ({
        ...prevData,
        [fieldName]: prevData[fieldName].map((item, idx) =>
          idx === index ? { ...item, [fieldName]: value } : item
        ),
      }));
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleFileChange = (e, index) => {
    const files = e.target.files;
    if (files && files[0]) {
      let newImageFiles = [...imageFiles];
      newImageFiles[index] = files[0];
      setImageFiles(newImageFiles);
    }
  };

  const addNewItem = (field) => {
    if (field === 'imagenes') {
      setImageFiles([...imageFiles, undefined]);
      setFormData({
        ...formData,
        [field]: [...formData[field], { url: '' }],
      });
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [field]: [...prevData[field],{}],
      }));
    }
  };

  useEffect(() => {
    fetch("https://ehr-mall-server.onrender.com/api/categorias")
      .then((response) => response.json())
      .then((data) => setCategoriasP(data))
      .catch((error) => console.error("Error al cargar las categorias ", error));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Subir las imágenes y obtener sus URL
    const imageUploadPromises = imageFiles.map((file) => {
      if (file) {
        const formData = new FormData();
        formData.append('imagen', file);
        return axios.post('https://ehr-mall-server.onrender.com/api/imagenes/upload', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        }).then((response) => response.data.url);
      }
      return Promise.resolve('');
    });
  
    // Esperar a que todas las imágenes se suban
    const imageUrls = await Promise.all(imageUploadPromises);
  
    // Construir el objeto newFormData con la estructura adecuada
    const newFormData = {
      nombre: formData.nombre,
      descripcion: formData.descripcion,
      precio: parseFloat(formData.precio),
      subCategoria: formData.subCategoria,
      stock: parseInt(formData.stock),
      imagenes: imageUrls.filter((url) => url !== ''),
      color: formData.color.map((item) => item.color),
      talla: formData.talla.map((item) => item.talla),
      descuento: parseInt(formData.descuento),
      categoriaP: formData.categoriaP,
      tiempoEntrega: formData.tiempoEntrega,
      valoracion: parseInt(formData.valoracion),
    };
  
    try {
      // Realizar la solicitud POST para crear el producto
      await axios.post('https://ehr-mall-server.onrender.com/api/productos', newFormData);
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Producto creado correctamente",
        showConfirmButton: false,
        timer: 1500
      });
      navigate('/admin/listaProductos'); // Redirigir a la página de administrador después del éxito
    } catch (error) {
      console.error('Error al crear el producto:', error);
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Error a registrar",
        showConfirmButton: false,
        timer: 1500
      });
    }
  };
  
  

  const nextSection = () => {
    setSection((currentSection) => currentSection + 1);
  };

  const prevSection = () => {
    setSection((currentSection) => Math.max(currentSection - 1, 0));
  };

  return (
    <div className="estiloContenedor">
      <Link to="/admin">
        <br />
        <div className="incon_house">&#127968;</div>
      </Link>
      <h1 className="estiloTitulo">Registro Producto</h1>
      <form onSubmit={handleSubmit} autoComplete="off">
        {section === 0 && (
          <>
            {/* Sección 0: Datos básicos */}
            <div className="estiloCampo">
              <label htmlFor="nombre" className="estiloEtiqueta">
                &#128100; Nombre
              </label>
              <input
                autoComplete="off"
                type="text"
                placeholder="Nombre"
                name="nombre"
                className="estiloInput"
                value={formData.nombre}
                onChange={handleChange}
                required
              />
            </div>
            <div className="estiloCampo">
              <label htmlFor="descripcion" className="estiloEtiqueta">
                &#128100; Descripción
              </label>
              <textarea
                autoComplete="off"
                placeholder="Descripción del producto"
                name="descripcion"
                className="estiloInput"
                value={formData.descripcion}
                onChange={handleChange}
                required
              />
            </div>
            <div className="estiloCampo">
              <label htmlFor="precio" className="estiloEtiqueta">
                &#128100; Precio
              </label>
              <input
                autoComplete="off"
                type="number"
                placeholder="Precio"
                name="precio"
                className="estiloInput"
                value={formData.precio}
                onChange={handleChange}
                required
              />
            </div>
            <div className="estiloCampo">
              <label htmlFor="subCategoria" className="estiloEtiqueta">
                &#128100; Subcategoría
              </label>
              <input
                autoComplete="off"
                type="text"
                placeholder="Subcategoría"
                name="subCategoria"
                className="estiloInput"
                value={formData.subCategoria}
                onChange={handleChange}
                required
              />
            </div>
            <br></br>
          </>
        )}
        {section === 1 && (
          <>
            {/* Sección 1: Stock e Imágenes */}
            <div className="estiloCampo">
              <label htmlFor="stock" className="estiloEtiqueta">
                &#128100; Stock
              </label>
              <input
                autoComplete="off"
                type="text"
                placeholder="Stock"
                name="stock"
                className="estiloInput"
                value={formData.stock}
                onChange={handleChange}
                required
              />
            </div>
            {formData.imagenes.map((foto, index) => (
              <div key={`foto-${index}`} className="estiloCampo">
                <label className="estiloEtiqueta">{`Imagen ${index + 1}`}</label>
                <input
                  className="estiloInput"
                  type="file"
                  onChange={(e) => handleFileChange(e, index)}
                  required
                />
              </div>
            ))}
            <button type="button" onClick={() => addNewItem('imagenes')}>
              Añadir otra imagen
            </button>
            <br></br>
          </>
        )}
        {section === 2 && (
          <>
            {/* Sección 2: Colores */}
            {formData.color.map((color, index) => (
              <div key={`color-${index}`} className="estiloCampo">
                <label className="estiloEtiqueta">{`Color ${index + 1}`}</label>
                <input
                  className="estiloInput"
                  type="text"
                  name={`color.${index}.color`}
                  value={color.color}
                  onChange={(e) => handleChange(e, index)}
                  required
                />
              </div>
            ))}
            <button type="button" onClick={() => addNewItem('color')}>
              Añadir otro color
            </button>
            <br></br>
          </>
        )}
        {section === 3 && (
          <>
            {/* Sección 3: Tallas */}
            {formData.talla.map((talla, index) => (
              <div key={`talla-${index}`} className="estiloCampo">
                <label className="estiloEtiqueta">{`Talla ${index + 1}`}</label>
                <input
                  className="estiloInput"
                  type="text"
                  name={`talla.${index}.talla`}
                  value={talla.talla}
                  onChange={(e) => handleChange(e, index)}
                  required
                />
              </div>
            ))}
            <button type="button" onClick={() => addNewItem('talla')}>
              Añadir otra talla
            </button>
            <br></br>
          </>
        )}
        {section === 4 && (
          <>
            {/* Sección 4: Descuento, CategoríaP, Tiempo de Entrega, Valoración */}
            <div className="estiloCampo">
              <label htmlFor="descuento" className="estiloEtiqueta">
                &#128100; Descuento
              </label>
              <input
                autoComplete="off"
                type="text"
                placeholder="Descuento"
                name="descuento"
                className="estiloInput"
                value={formData.descuento}
                onChange={handleChange}
                required
              />
            </div>
            <div className="estiloCampo">
              <label htmlFor="categoriaP" className="estiloEtiqueta">
                Selecciona una categoría
              </label>
              <select
                id="categoriaP"
                name="categoriaP"
                className="estiloInput"
                value={formData.categoriaP}
                onChange={handleChange}
                required
              >
                <option value="">Selecciona una categoría</option>
                {categoriasP.map((categoria) => (
                  <option key={categoria._id} value={categoria._id}>
                    {categoria.categoria}
                  </option>
                ))}
              </select>
            </div>
            <div className="estiloCampo">
              <label htmlFor="tiempoEntrega" className="estiloEtiqueta">
                &#128100; Tiempo de Entrega
              </label>
              <input
                autoComplete="off"
                type="text"
                placeholder="Tiempo de Entrega"
                name="tiempoEntrega"
                className="estiloInput"
                value={formData.tiempoEntrega}
                onChange={handleChange}
                required
              />
            </div>
            <div className="estiloCampo">
              <label htmlFor="valoracion" className="estiloEtiqueta">
                &#128100; Valoración
              </label>
              <input
                autoComplete="off"
                type="text"
                placeholder="Valoración"
                name="valoracion"
                className="estiloInput"
                value={formData.valoracion}
                onChange={handleChange}
                required
              />
            </div>
            <br></br>
          </>
        )}
  
        {/* Navegación entre secciones */}
        {section > 0 && (
          
          <button type="button" className="btn-4" onClick={prevSection}>
            Anterior
          </button>
        
        )}
        {section < 4 && (
          <button className="btn-4" type="button" onClick={nextSection}>
            Siguiente
          </button>
        )}
        {section === 4 && (
            <BtnGuardar >Crear Producto</BtnGuardar>
        )}
      </form>
    </div>
  )};
  
 
export  {ListaProductos, InsertarProductos};

 //   const url = `https://ehr-mall-server.onrender.com/api/productos/${productoEditar._id}`;