import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ContadorProductosPorCategoria = () => {
  const [categorias, setCategorias] = useState([]);
  const [productos, setProductos] = useState([]);
  const [contadoresPorCategoria, setContadoresPorCategoria] = useState({});

  useEffect(() => {
    const fetchCategorias = async () => {
      try {
        const response = await axios.get('https://ehr-mall-server.onrender.com/api/categorias');
        setCategorias(response.data);
      } catch (error) {
        console.error("Error al cargar las categorías", error);
      }
    };

    const fetchProductos = async () => {
      try {
        const response = await axios.get('https://ehr-mall-server.onrender.com/api/productos');
        setProductos(response.data);
      } catch (error) {
        console.error("Error al cargar los productos", error);
      }
    };

    fetchCategorias();
    fetchProductos();
  }, []); // Se ejecuta solo al montar el componente para cargar las categorías y productos

  useEffect(() => {
    const contarProductosPorCategoria = () => {
      const contadorPorCategoria = {};

      // Inicializar contador para cada categoría
      categorias.forEach(categoria => {
        contadorPorCategoria[categoria._id] = 0;
      });

      // Contar productos por categoría
      productos.forEach(producto => {
        if (contadorPorCategoria[producto.categoriaP] !== undefined) {
          contadorPorCategoria[producto.categoriaP]++;
        }
      });

      // Actualizar el estado con los contadores por categoría
      setContadoresPorCategoria(contadorPorCategoria);
    };

    // Llamar a la función de conteo cada vez que cambian las categorías o productos
    contarProductosPorCategoria();
  }, [categorias, productos]); // Se ejecuta cada vez que cambian las categorías o productos

  return (
    <div>
      <h2>Contadores de productos por categoría:</h2>
      <ul>
        {categorias.map(categoria => (
          <li key={categoria._id}>
            {categoria.categoria}: {contadoresPorCategoria[categoria._id]}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ContadorProductosPorCategoria;
