import React, { useEffect, useState } from 'react';
import Carouselis from '../carosel/carosel';
import ListaProductos from './listaProductosGeneral';
import { useNavigate } from "react-router-dom";
import './paginaP.css'

const INicioP = () => {
  const [productoss, setproductoss] = useState([]);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    cargarProductos();
  }, []);

  const cargarProductos = () => {
    fetch('https://ehr-mall-server.onrender.com/api/productos')
      .then(response => response.json())
      .then(data => {
        setproductoss(data);
        setLoading(false); // Marca la carga como completa
        
      })
      .catch(error => {
        console.error("Error al cargar los productos", error);
        setLoading(false); // Marca la carga como completa incluso en caso de error
      });
  };

 


  return (
    <div>
      <Carouselis />
      {loading ? (
        <>
 <div class="pyramid-loader">
  <div class="wrapper">
    <span class="side side1"></span>
    <span class="side side2"></span>
    <span class="side side3"></span>
    <span class="side side4"></span>
    <span class="shadow"></span>
  </div>  
</div>
        </>
      ) : (
        <>
        <ListaProductos products={productoss} />
    </>

        

      )}
      </div>
  );
};

export default INicioP;
