import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { AiOutlineWifi } from 'react-icons/ai';
import './styles.css'; // Archivo de estilos CSS
import { useParams, useNavigate } from 'react-router-dom';

const Carousel = () => {
  const [CDescuento, setCDescuento] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();
  const [usuarios, setUsuarios] = useState(JSON.parse(localStorage.getItem('usuario')) || {});

  useEffect(() => {
    axios.get('http://localhost:3001/api/productos')
      .then(response => {
        const filtroDescuento = response.data.filter(CDescuento =>CDescuento.descuento > 0);
        setCDescuento(filtroDescuento);
      })
      .catch(error => {
        console.error('Error al cargar los productos:', error);
      });
  }, []);

  const nextSlide = () => {
    const nextIndex = (currentIndex + 1) % CDescuento.length;
    setCurrentIndex(nextIndex);
  };

  const prevSlide = () => {
    if (CDescuento.length > 0) {
      const prevIndex = (currentIndex - 1 + CDescuento.length) % CDescuento.length;
      setCurrentIndex(prevIndex);
    }
  }
  
  useEffect(() => {
    if (CDescuento.length > 0) {
      const intervalId = setInterval(nextSlide, 3000);

      return () => clearInterval(intervalId);
    }
  }, [CDescuento, currentIndex]);

  if (CDescuento.length === 0) {
    return <div>Conectate a wifi <AiOutlineWifi size={24} color="blue" /></div>;
  }



  const handleProductClick = (productoId) => {
    const usuarioTipo = usuarios.tipo;
    if(usuarioTipo==='cliente'){
      navigate(`/client/detalles/${productoId}` )
    }else{
      navigate(`/detalles/${productoId}`)
    }
  };


  

  return (
    <div className="carousel-container m-3 ">
      <div id="carouselExampleCaptions" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
          {CDescuento.map((productos, index) => (
            <div
              key={productos._id} onClick={() => handleProductClick(productos._id)}
              className={`carousel-item ${index === currentIndex ? 'active' : ''}`}
            >
              <div className="d-flex justify-content-center align-items-center ">
                <img
                  src={productos.imagenes[0]}
                  className="img-fluid d"
                  alt={productos.nombre}
                  style={{ maxWidth: '70vh', maxHeight: '400px' }} // Establecer máximo ancho y altura
                />
              </div>
              <div className="d-flex p-2 desarrollo">
                <h1 className="nombre"><strong>{productos.nombre}</strong></h1>
                <h2 className='precio'><strong>Precio original:</strong> <h1 className='colorR'>${productos.precio}</h1></h2>
                <h2 className='precio'>
                <strong>Precio con descuento:</strong> <h2 className='colorV'> ${((productos.precio * (100 - productos.descuento)) / 100).toFixed(2)}
                </h2></h2>


              </div>
            </div>
          ))}
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="prev"
          onClick={prevSlide}
        >
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleCaptions"
          data-bs-slide="next"
          onClick={nextSlide}
        >
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
};

export default Carousel;
