import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import InicioP from "./Components/public/inicio/paginaInicioP";
import Login from "./Components/shared/autetication/LoginForm";
import LayoutConEncabezado from "./Paginas/Layouts/layoutEncabezados";
import RutaPrivada from "./Paginas/Layouts/contextoAutentication";
import {Usuarios, InsertarUsuario} from "./Components/Admin/Usuarios/Usuario";
import { ListaCategoria, InsertarCategoria } from "./Components/Admin/Categoria/categoria";
import {QuienesSomos,MisionVision,PoliticaPrivacidad} from "./Components/Admin/InformacionEmpresa/informacionEmpresa";
// import  {CrudProductos, CrudinsertarProducto} from "./Components/Admin/Productos/productos";
import {ListaProductos, InsertarProductos} from "./Components/Admin/Productos/productos";
import ContadorProductosPorCategoria from "./Components/Admin/Productos/ejemplo"
import ProductosPorCategoria from "./Components/Admin/Productos/ejemplo2";
import Registro from "./Components/shared/autetication/Registro";
import PerfilClient from "./Components/client/perfil/perfilClient";
import ProductosFiltradosPublico from "./Components/client/productos/productos";
import Example from "./Components/shared/inicio";
import DetallesProducto from '../src/Components/client/productos/detalleProducto'
import CarritoCompra from '../src/Components/client/pedido/pedidosClient'
import { CarritoProvider } from "./Paginas/Layouts/contextCarritoCompras";
import ViewSubCategorias from "./Components/client/productos/ViewSubcategorias";

const  App =  () => {
  return (
    <>

<CarritoProvider>
    <Routes>
  
       {/* Rutas Publicas */}
      <Route path="/" element={<LayoutConEncabezado><InicioP /> </LayoutConEncabezado>}></Route>
      <Route path="/detalles/:productoId" element={<LayoutConEncabezado><DetallesProducto /></LayoutConEncabezado>} />
      <Route path="/categorias/:categoria/:productoId" element={<LayoutConEncabezado><DetallesProducto /></LayoutConEncabezado>} />
      <Route path="/login" element={<LayoutConEncabezado><Login /></LayoutConEncabezado>}></Route>
      <Route path="/pedido" element={<LayoutConEncabezado><CarritoCompra></CarritoCompra></LayoutConEncabezado> }/>
      <Route path="/registro" element={<LayoutConEncabezado><Registro /></LayoutConEncabezado> }/>
      <Route path="/categorias/:categoria" element={<LayoutConEncabezado><ProductosFiltradosPublico /></LayoutConEncabezado>} />
      < Route path="/subCategoria/:subcategoria" element={<LayoutConEncabezado><ViewSubCategorias/></LayoutConEncabezado>} />

        {/* Rutas Clientes */}
     < Route path="/client" element={<RutaPrivada rolesPermitidos={['cliente']}><LayoutConEncabezado><InicioP /></LayoutConEncabezado></RutaPrivada>} />
     < Route path="/client/pedido" element={<RutaPrivada rolesPermitidos={['cliente']}><LayoutConEncabezado><CarritoCompra/></LayoutConEncabezado></RutaPrivada>} />
     < Route path="client/subCategoria/:subcategoria" element={<RutaPrivada rolesPermitidos={['cliente']}><LayoutConEncabezado><ViewSubCategorias/></LayoutConEncabezado></RutaPrivada>} />

   
      



 



   
     




     < Route path="/client/perfilClient" element={<RutaPrivada rolesPermitidos={['cliente']}><LayoutConEncabezado><PerfilClient /></LayoutConEncabezado></RutaPrivada>} />
     < Route path="/client/detalles/:productoId" element={<RutaPrivada rolesPermitidos={['cliente']}><LayoutConEncabezado><DetallesProducto/></LayoutConEncabezado></RutaPrivada>} />
     < Route path="/client/categorias/:categoria/:productoId" element={<RutaPrivada rolesPermitidos={['cliente']}><LayoutConEncabezado><DetallesProducto/></LayoutConEncabezado></RutaPrivada>} />
     < Route path="/client/categorias/:categoria" element={<RutaPrivada rolesPermitidos={['cliente']}><LayoutConEncabezado><ProductosFiltradosPublico/></LayoutConEncabezado></RutaPrivada>} />
    

        {/* Rutas Administrativas */}
        <Route path="/admin" element={<RutaPrivada rolesPermitidos={['administrador']}><LayoutConEncabezado><Login /></LayoutConEncabezado></RutaPrivada>} />
        <Route path="/admin/usuarios" element={<RutaPrivada rolesPermitidos={['administrador']}><LayoutConEncabezado><Usuarios /></LayoutConEncabezado></RutaPrivada>} />
        <Route path="/admin/insertarusuarios" element={<RutaPrivada rolesPermitidos={['administrador']}><LayoutConEncabezado><InsertarUsuario /></LayoutConEncabezado></RutaPrivada>} />
        <Route path="/admin/categoria" element={<RutaPrivada rolesPermitidos={['administrador']}><LayoutConEncabezado><ListaCategoria /></LayoutConEncabezado></RutaPrivada>} />
        <Route path="/admin/insertarCategoria" element={<RutaPrivada rolesPermitidos={['administrador']}><LayoutConEncabezado><InsertarCategoria /></LayoutConEncabezado></RutaPrivada>} />
        <Route path="/admin/quienesSomos" element={<RutaPrivada rolesPermitidos={['administrador']}><LayoutConEncabezado><QuienesSomos /></LayoutConEncabezado></RutaPrivada>} />
        <Route path="/admin/misionVision" element={<LayoutConEncabezado><MisionVision /></LayoutConEncabezado> }/>
        <Route path="/admin/politicas" element={<LayoutConEncabezado><PoliticaPrivacidad /></LayoutConEncabezado> }/>
        {/* <Route path="/admin/listaProductos" element={<LayoutConEncabezado><CrudProductos /></LayoutConEncabezado> }/>
        <Route path="/admin/insertarProductos" element={<LayoutConEncabezado><CrudinsertarProducto /></LayoutConEncabezado> }/> */}
         <Route path="/admin/listaProductos" element={<LayoutConEncabezado><ListaProductos /></LayoutConEncabezado> }/>
        <Route path="/admin/insertarProductos" element={<LayoutConEncabezado><InsertarProductos /></LayoutConEncabezado> }/>
        <Route path="/admin/ejemplo" element={<LayoutConEncabezado><ContadorProductosPorCategoria /></LayoutConEncabezado> }/>
        <Route path="/admin/ejemplo2" element={<LayoutConEncabezado><ProductosPorCategoria /></LayoutConEncabezado> }/>
       
    
    </Routes>
    </CarritoProvider>
    
   
    </>
  );
}


export default App;
