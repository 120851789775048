import React, { createContext, useContext, useState } from 'react';

const CarritoContext = createContext();

export const CarritoProvider = ({ children }) => {
  const [productosEnCarrito, setProductosEnCarrito] = useState([]);

  const agregarProductoAlCarrito = (productos) => {
    setProductosEnCarrito([...productosEnCarrito, productos]);
  };

  const editarProductoEnCarrito = (index, nuevoProducto) => {
    const nuevosProductos = [...productosEnCarrito];
    nuevosProductos[index] = nuevoProducto;
    setProductosEnCarrito(nuevosProductos);
  };

  const eliminarProductoDelCarrito = (index) => {
    const nuevosProductos = [...productosEnCarrito];
    nuevosProductos.splice(index, 1);
    setProductosEnCarrito(nuevosProductos);
  };

  const vaciarCarrito = () => {
    setProductosEnCarrito([]);
  };

  return (
    <CarritoContext.Provider
      value={{
        productosEnCarrito,
        agregarProductoAlCarrito,
        editarProductoEnCarrito,
        eliminarProductoDelCarrito,
        vaciarCarrito
      }}
    >
      {children}
    </CarritoContext.Provider>
  );
};

export const useCarritoContext = () => {
  return useContext(CarritoContext);
};
