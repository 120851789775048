// Dentro del componente ColorList
import React from 'react';

const ColorList = ({ colores,selectedColor, onColorChange }) => {
  
    const colorMap = {
        rosa: '#FFC0CB',
        azul: '#0000FF',
        verde: '#008000',
        morado:'#G0AB0'
        // Puedes agregar más nombres de colores y sus códigos aquí
    };

    const handleColorChange = (color) => {
        onColorChange(color);
      };
    

    return (
        <div className='listaColores'>
            <h2 className='detalleColor'>Color:</h2>
            {colores.map((color, index) => {
                // Verificar que color sea una cadena de texto válida
                if (typeof color !== 'string') {
                    console.warn(`El color en la posición ${index} no es una cadena válida:`, color);
                    return null; // O manejar de otra forma si color no es válido
                }

                // Convertir a minúsculas y obtener el código de color del mapa
                const codigoColor = colorMap[color.toLowerCase()] || 'white';

                return (
                    <div className='inputColor' key={index}>
                     
                        <input
                            className='inputColor'
                            type="text"
                            key={index}
                            value={selectedColor === color ? color : ''}
                           
                            
                            // readOnly
                            style={{ backgroundColor: codigoColor ,
                                border: selectedColor==color? '2px solid black' : 
                                '1px solid #ccc',
                                cursor: 'pointer',
                            }}
                            onClick={() => handleColorChange(color)}
                        >
                    </input>
                       
                    </div>
             
                );
            })}
        </div>
    );
};

export default ColorList;
