import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import bcrypt from 'bcryptjs';
import { Link } from 'react-router-dom';
import Swal from "sweetalert2";
import '../../Admin/Usuarios/usuarios.css';
import {BotonR,BotonV,BtnCancelar,BtnGuardar}  from '../../Admin/Usuarios/boton';

const Registro=()=>{
    const [section, setSection] = useState(0);
    const navigate=useNavigate();
    const [usuarios, setUsuarios] = useState([]);
    const [preguntas, setPreguntas] = useState([]);
    const [confirmarP, setconfirmarP] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [passwordShown, setPasswordShown]= useState(false);
    const [forData, setFormData]=useState({    
        nombre: "",
        username:" ",
        apellidoP:"",
        apellidoM:"",
        correo:"",
        telefono:"",
        tipo:"cliente",
        id_pregunta:"",
        respuesta:"",
        password:"",
        direccion: {
          calle: 'sin calle',
          localidad: 'sin localidad',
          municipio: 'sin municipio ',
          estado: 'sin estado',
          pais: 'sin pais',
          codigo_postal: 'sin codigo postal',
          detalles:'sin detalles',
    
         }
     });


    //funcion
  useEffect(() => {
    //Realizamos la consulta para usuarios
    axios.get("https://ehr-mall-server.onrender.com/api/usuarios")
    .then(response => {
      // Almacenar los datos de los usuarios en el estado
      setUsuarios(response.data);
    })
    .catch(error => console.error("Error al cargar los usuarios: ", error));
  }, []);

  useEffect(() => {
    //Para hacer consulta pregunta
    fetch("https://ehr-mall-server.onrender.com/api/pregunta")
      .then((response) => response.json())
      .then((data) => setPreguntas(data))
      .catch((error) => console.error("Error al cargar las preguntas ", error));
  }, []);

  //Registro
  
  const hadleChange = (e)=>{
    const {name,value}= e.target;
    //insertamos los datos
    setFormData({
      ...forData,
      [name]:value,
    });
    

     // Validar campos según el nombre 
    // Validar campos según el nombre
    switch (name) {
        case "correo":
          const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
          setErrorMessage(isValidEmail ? "" : "Correo electrónico inválido");
          break;
        case "telefono":
          const isValidPhone = /^[0-9]{10}$/.test(value);
          setErrorMessage(isValidPhone ? "" : "Teléfono inválido (10 dígitos numéricos)");
          break;
        case "password":
          const isValidPassword = value.length >= 8;
          setErrorMessage(isValidPassword ? "" : "La contraseña debe tener al menos 8 caracteres");
          break;
        default:
          setErrorMessage("");
          break;
      }
    };






    //Aqui termina la validacion

  
  //Conprobar que las contraseñas considan
  const handleSubmit = async(e)=>{
    e.preventDefault();
    if (forData.password !== confirmarP) {
        setErrorMessage('Las contraseñas no coinciden.');
        return;
      }

  //Encrptamos la contraseña a insertar
  const hashePassword = await bcrypt.hash(forData.password, 10);
  //creamos un objeto con la contraseña encriptado
  const formDataPassworEncryptado={
    ...forData,
    password: hashePassword,
  };
  //VERIFICAMOS si el correo a insertar no se encuenta en la base de datos
  try{
    const usuarioCorreo = usuarios.find(usuario=>usuario.correo===forData.correo);
    //Comparamos el usuario
    const usuarioUsername= usuarios.find(usuario=>usuario.username===forData.username);


    if(usuarioCorreo && usuarioUsername){
        setErrorMessage("El usuario y el correo ya estan registrado");
      
    }else if(usuarioCorreo){
        setErrorMessage('Error el correo '+forData.correo + ' ya esta registrado.');
    }else if(usuarioUsername){
        setErrorMessage('Error el usuario '+forData.username + ' ya esta registrado.');
    }else {
      await axios.post(
        "https://ehr-mall-server.onrender.com/api/usuarios",
        formDataPassworEncryptado

      );
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Tu registro fue exitoso",
        showConfirmButton: false,
        timer: 1500
      });
      //nos dirigimos alas lista de user
      navigate("/login")

    }

  }catch (error){
    
  Swal.fire({
  position: "top-end",
  icon: "error",
  title: "Error a registrar",
  showConfirmButton: false,
  timer: 1500
});

  }

  }




    //Funcion para ir al siguiente pagina o anterior
    
  const nextSection = () => {
    setSection((currentSection) => currentSection + 1);
  };

  const prevSection = () => {
    setSection((currentSection) => Math.max(currentSection - 1, 0));
  };


  const isNextButtonEnabled = () => {
    if (section === 0) {
      return (
       forData.nombre &&
       forData.apellidoP &&
       forData.apellidoM &&
       forData.correo &&
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(forData.correo)
      );
    } else if (section === 1) 
    {
      return (
       forData.username &&
       forData.telefono &&
        /^[0-9]{10}$/.test(forData.telefono) &&
        forData.id_pregunta &&
        forData.respuesta
      );
      
    } else {
      return (
        forData.password &&
        confirmarP &&
       forData.password.length >= 8 &&
       forData.password === confirmarP



      )
    }
  };

   // Función para alternar la visibilidad de la contraseña
   const togglePasswordVisibility=()=>{
    setPasswordShown(!passwordShown);
  };




    return(

        <>
  <div className="estiloContenedor-R1">
       <form onSubmit={handleSubmit} autoComplete='off'>

         {/* Mostrar mensaje de error */}
         {errorMessage && (
          <p style={{ color: "red" }}>{errorMessage}</p>
        )}
       
       {section === 0 && (
        <>
          <header class="head-form" autoComplete="off">
            <h2>Registro</h2>
             <img src={require("../../img/Logos/inconoEhrMall.png")} alt="Logo"  className="logo"/>
          </header>

          <div className="estiloCampo">
            <label htmlFor="nombre" className="estiloEtiqueta">
              &#128100; Nombre
            </label>
            <input
              autoComplete="off"
              type="text"
              placeholder="Nombre"
              name="nombre"
              class="form-input-1" 
              value={forData.nombre}
              onChange={hadleChange}
              title="Solo se aceptan letras"
              required
              minLength={4}
              maxLength={25}
              pattern="[a-zA-Z]*"
            />
            <br />
            <br />
          </div>



    <div className="estiloCampo">
            <label
              htmlFor="apellidoP"
              className="estiloEtiqueta"
            >
              &#128100; Apellido Paterno:
            </label>
            <input
              autoComplete="off"
              type="text"
              minLength={4}
              maxLength={25}
              pattern="[a-zA-Z]*"
              placeholder="Apellido Paterno"
              name="apellidoP"
              class="form-input-1" 
              value={forData.apellidoP}
              onChange={hadleChange}
              title="Solo se aceptan letras"
              required
            />
    </div>

    <div className="estiloCampo">
            <label
              htmlFor="apellidoM"
              className="estiloEtiqueta"
            >
              &#128100; Apellido Materno:
            </label>
            <input
              autoComplete="off"
              type="text"
              minLength={4}
              maxLength={25}
              pattern="[a-zA-Z]*"
              placeholder="Apellido Materno"
              name="apellidoM"
              class="form-input-1" 
              value={forData.apellidoM}
              onChange={hadleChange}
              
              title="Solo se aceptan letras"
              required
            />
          </div>

          <div className="estiloCampo">
            <label htmlFor="correo" className="estiloEtiqueta">
              &#9993; Correo Electrónico:
            </label>
            <input
              autoComplete="off"
              type="email"
              name="correo"
              placeholder="Correo electrónico"
              class="form-input-1"
              value={forData.correo}
              onChange={hadleChange} 
              required
              title="Formato invalido"
              minLength={8}
              maxLength={30} // Ajustado a un valor razonable, puede ser mayor si lo deseas
              pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$" // Expresión regular para validar correo electrónico
            />
          </div>
          </>
       )}
       {section === 1 && (
        <>
        <div className="estiloCampo">
            <label htmlFor="username" className="estiloEtiqueta">
              &#128100; Usuario:
            </label>
            <input
              autoComplete="off"
              type="text"
              name="username"
              placeholder="Username"
              className="estiloInput"
              value={forData.username}
              onChange={hadleChange}
              maxLength={15}
              
              minLength={4}
              title="Solo se acepta numeros y letras"
              required
            />
          </div>
          <div className="estiloCampo">
            <label htmlFor="telefono" className="estiloEtiqueta">
              &#128222; Teléfono:
            </label>
            <input
              autoComplete="off"
              type="tel"
              name="telefono"
              placeholder="Teléfono"
              className="estiloInput"
              value={forData.telefono}
              onChange={hadleChange}
              required
              maxLength={10}
              minLength={10}
              title="solo se aceptan numeros"
              pattern="[0-9]*"
            />
          </div>
          <div className="estiloCampo">
            <label htmlFor="id_pregunta" className="estiloEtiqueta">
              Selecciona una pregunta:
            </label>
            <select
              id="id_pregunta"
              name="id_pregunta"
              className="estiloInput"
              value={forData.id_pregunta}
              onChange={hadleChange}
              
              required
            >
              <option value="">Seleccione una pregunta</option>
              {preguntas.map((pregunta) => (
                <option key={pregunta._id} value={pregunta._id}>
                  {pregunta.pregunta}
                </option>
              ))}
            </select>
          </div>
          
          <div className="estiloCampo">
            <label
              htmlFor="datos_cliente.pregunta.respuesta"
              className="estiloEtiqueta"
            >
              &#128274; Respuesta:
            </label>
            <input
              autoComplete="off"
              type="text"
              name="respuesta"
              placeholder="respuesta"
              className="estiloInput"
              value={forData.respuesta}
              onChange={hadleChange}
              minLength={4}
              maxLength={12}
              required
            />
          </div>
          
         


        
        
        </>
       )}
       {section === 2 && (
        <>
          <div className="estiloCampo">
            <label htmlFor="password" className="estiloEtiqueta">
              &#128274; Contraseña:
            </label>
            <input
              autoComplete="off"
              type={passwordShown ? 'text' : 'password'}
              name="password"
              id="pwd"
              value={forData.password}
              onChange={hadleChange}
              className="estiloInput"
              pattern="^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
              title="La contraseña debe tener al menos 8 caracteres, incluyendo al menos una letra mayúscula, una letra minúscula, un número y un carácter especial."
              minLength={8}
             
              placeholder="Ingrese una contraseña segura"
    
              required
            />
            <span onClick={ togglePasswordVisibility} style={{ cursor: 'pointer' }}>
             {passwordShown ? (
          <i className="fa fa-eye" aria-hidden="true" id='eye-1'></i>
        ) : (
          <i className="fa fa-eye" aria-hidden="true" id='eye-1'></i>
        )}
      </span>
          </div>

          <div className="estiloCampo">
            <label htmlFor="confirmarPassword" className="estiloEtiqueta">
              &#128274; Confirmar Contraseña:
            </label>
            <input
              autoComplete="off"
              type="password"
              name="confirmarPassword"
              placeholder="Confirmar Contraseña"
              className="estiloInput"
              minLength={8}
              value={confirmarP} 
              onChange={(e) => setconfirmarP(e.target.value)}
              required
              
            />
          </div>
        
        
        </>
       )}

         {/* Navegación entre secciones */}
         {section > 0 && (
          
          <button type="button" className="btn-4" onClick={prevSection}>
            Anterior
          </button>
        
        )}
        {section < 2 && (
         <button
         className="btn-4"
         type="button"
         onClick={nextSection}
         disabled={!isNextButtonEnabled()}
       >
         Siguiente
       </button>
        
        )}
        {section === 2 && (
            <BtnGuardar >Registrar</BtnGuardar>
        )}
</form>
</div>
    

        
        
        </>
    )
}


export default Registro;