import React, { useEffect, useState } from 'react';
import { useCarritoContext } from '../../../Paginas/Layouts/contextCarritoCompras';
import { DateTime } from 'luxon'; // Importar DateTime de luxon

const Carrito = () => {
  const { productosEnCarrito, editarProductoEnCarrito, eliminarProductoDelCarrito, vaciarCarrito } = useCarritoContext();
  const [usuarios] = useState(JSON.parse(localStorage.getItem('usuario')) || {});
  const [errorMessage, setErrorMessage] = useState("");

  const fechaPedidoMexico = DateTime.now().setZone('America/Mexico_City').toISO();

  const [formData, setFormData] = useState({
    usuario_id: usuarios._id,
    nombreUsuario: usuarios.nombre,
    productos: [],
    direccion_envio: {
      calle: usuarios.direccion.calle,
      ciudad: usuarios.direccion.ciudad,
      estado: usuarios.direccion.estado,
      codigo_postal: usuarios.direccion.codigo_postal,
      pais: usuarios.direccion.pais,
      detalles: usuarios.direccion.detalles,
    },
    estado: "pendiente",
    fecha_pedido: fechaPedidoMexico,
    total: 0,
    notas: "",
    fechaEntrega: usuarios.tiempoEntrega,
  });

  const enviarPedidoAlServidor = async () => {
    try {
      const nuevosProductos = productosEnCarrito.map(producto => ({
        producto_id: producto.id,
        cantidad: producto.cantidad,
        talla: producto.talla,
        color: producto.color,
        subtotal: producto.precio * producto.cantidad
      }));

      const pedidoActualizado = {
        ...formData,
        productos: nuevosProductos,
        total: nuevosProductos.reduce((total, producto) => total + producto.subtotal, 0)
      };

      const response = await fetch('https://ehr-mall-server.onrender.com/api/pedidos', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(pedidoActualizado)
      });

      if (response.ok) {
        console.log('Pedido enviado correctamente');
        // Limpiar carrito después de enviar el pedido (opcional)
        vaciarCarrito();
      } else {
        throw new Error('Error al enviar el pedido');
      }
    } catch (error) {
      console.error('Error al enviar el pedido:', error);
    }
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      enviarPedidoAlServidor();
    };

    const handleLogout = () => {
      enviarPedidoAlServidor(); // Enviar pedido al cerrar sesión
      localStorage.removeItem('usuario'); // Limpiar el usuario del localStorage al cerrar sesión
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [productosEnCarrito, vaciarCarrito]);

  return (
    <div>
      <h2>Carrito de Compras</h2>
      {productosEnCarrito.map((producto, index) => (
        <div key={index}>
          <p>{producto.nombre} - {producto.precio}</p>
          <p>{producto.color} - {producto.cantidad}</p>
          <p>{producto.talla} - {producto.id}</p>
          <button onClick={() => editarProductoEnCarrito(index, { ...producto, cantidad: producto.cantidad + 1 })}>Aumentar Cantidad</button>
          <button onClick={() => eliminarProductoDelCarrito(index)}>Eliminar</button>
        </div>
      ))}
    </div>
  );
};

export default Carrito;
