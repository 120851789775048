import React, { useEffect, useState} from 'react';
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios"; 
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';

const ViewSubCategorias = () => {
  const navigate = useNavigate();
    const location = useLocation();
    const subcategoria = location.state.subcategoria;
    const [productos, setProductos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalPaginas, setTotalPaginas] = useState(0);
    const [paginaActual, setPaginaActual] = useState(1);
    const [errorMessage, setErrorMessage] = useState("");
    const [usuarios, setUsuarios] = useState(JSON.parse(localStorage.getItem('usuario')) || {});
  
  

    useEffect(() => {
      const fetchData = async () => {
        console.log(subcategoria)
        try {
          const response = await fetch(`https://ehr-mall-server.onrender.com/api/productos/categ/${subcategoria}?page=${paginaActual}&limit=4`);
          if (!response.ok) {
            throw new Error('Error fetching productos');
          }
          const data = await response.json();
          setProductos(data.productos);
          console.log("este es subcategroias: ", data.productos)
  
          setTotalPaginas(data.totalPaginas);
          setLoading(false); // Marca la carga como complet0
  
        } catch (error) {
          console.error('Error fetching productos:', error);
          setLoading(false); // Marca la carga como completa incluso en caso de error
        }
      };
      fetchData();
    }, [subcategoria, paginaActual]);
   

     // Genera los números de página
  const paginas = [];
  for (let i = 1; i <= totalPaginas; i++) {
    paginas.push(i);
  }
 
  
  const handleProductClick = (productoId) => {
    const path = usuarios.tipo==='cliente'? `/client/categorias/${subcategoria}/${productoId}` : `/categorias/${subcategoria}/${productoId}`;
    navigate(path);
  };


    

  return (
    <>
      {loading ? (
        <div className="pyramid-loader">
          <div className="wrapper">
            <span className="side side1"></span>
            <span className="side side2"></span>
            <span className="side side3"></span>
            <span className="side side4"></span>
            <span className="shadow"></span>
          </div>
        </div>
      ) : (
        <div className="containers">
          {productos.map((producto) => (
            <div
              className="card"
              key={producto._id}
              onClick={() => handleProductClick(producto._id)}
            >
              <div className="card-img">
                {/* Usamos producto.imagenes[0] para obtener la URL de la primera imagen del producto */}
                <img
                  src={producto.imagenes[0]}
                  alt={producto.nombre}
                  className="logos"
                />
              </div>
              <div className="card-title">{producto.nombre}</div>
              <div className="card-subtitle">{producto.descripcion}</div>
              <p className="card-divider" />
              <div className="card-footer">
                <div className="card-price">
                  <span>$</span> {producto.precio}
                </div>
                <button className="card-btn">
                  <FontAwesomeIcon
                    icon={faShoppingCart}
                    className="cart-icon"
                    size={10}
                  />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}

  export default ViewSubCategorias