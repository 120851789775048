import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import { ArrowLeftOutlined } from '@ant-design/icons';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../../public/carosel/carousel.css'; // Archivo de estilos CSS
import './datellae.css';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import ColorList from './color';
import TallaLista from './tallas';
import Botoness from '../../public/botones/btn';
import { useCarritoContext } from '../../../Paginas/Layouts/contextCarritoCompras';
import { DateTime } from 'luxon'; // Importar DateTime de luxon
import axios from 'axios';

const SeccionDesplegable = ({ titulo, children }) => {
  const [abierto, setAbierto] = useState(false);


  return (
    <div className='seccion'>
      <div style={{fontWeight:'bold'}} onClick={() => setAbierto(!abierto)}>
        {titulo}
        {abierto ? <FaChevronUp /> : <FaChevronDown />}
      </div>
      {abierto && <div className='menuDesplegable'>{children}</div>}
    </div>
  );
};



const DetallesProducto = () => {
  const { productoId } = useParams();
  const fechaPedidoMexico = DateTime.now().setZone('America/Mexico_City').toISO();

  const [producto, setProducto] = useState(null);
  const [cantidad, setCantidad] = useState('');
  const [selectedColor, setSelectedColor] = useState('');
  const [selectedTalla, setSelectedTalla] = useState('');
  
  const [pedidosC, setPedidosC] = useState([]);
  const navigate = useNavigate();
  const { agregarProductoAlCarrito } = useCarritoContext();

  const [usuarios, setUsuarios] = useState(JSON.parse(localStorage.getItem('usuario')) || {});
  const [errorMessage, setErrorMessage] = useState("");

 const [formData, setFormData] = useState({
    usuario_id: usuarios ? usuarios._id : null,
    nombreUsuario: usuarios ? usuarios.nombre : '',
    productos: [],
    direccion_envio: {
      calle: usuarios && usuarios.direccion ? usuarios.direccion.calle : '',
      estado: usuarios && usuarios.direccion ? usuarios.direccion.estado : '',
      codigo_postal: usuarios && usuarios.direccion ? usuarios.direccion.codigo_postal : '',
      pais: usuarios && usuarios.direccion ? usuarios.direccion.pais : '',
      detalles: usuarios && usuarios.direccion ? usuarios.direccion.detalles : '',
    },
    estado: 'pendiente',
    fecha_pedido: fechaPedidoMexico,
    total: 0,
    notas: '',
    fechaEntrega: usuarios ? usuarios.tiempoEntrega : '',
  });

  //Hacemos la consulta de los pedidos
  useEffect(() => {
    const fetchPedidos= async()=>{
      try{
        const response = await fetch(`https://ehr-mall-server.onrender.com/api/pedidos`);


      }catch{


      }
    }




  },[])

  

  useEffect(() => {
    const fetchProducto = async () => {
      try {
        const response = await fetch(`https://ehr-mall-server.onrender.com/api/productos/detalles/${productoId}`);
        if (response.ok) {
          const data = await response.json();
          setProducto(data);
        } else {
          throw new Error('Error al obtener los detalles del producto');
        }
      } catch (error) {
        console.error('Error fetching producto:', error);
      }
    };

    fetchProducto();

    const intervalId = setInterval(fetchProducto, 5000);

    return () => clearInterval(intervalId);
  }, [productoId]);


  
  useEffect(() => {
    const fetchPedidos = async () => {
      try {
        const response = await fetch(`https://ehr-mall-server.onrender.com/api/pedidos/${usuarios._id}`);
        if (response.ok) {
          const data = await response.json();
          setPedidosC(data);
        } else {
          throw new Error('Error al obtener los detalles del Pedidos');
        }
      } catch (error) {
        console.error('Error fetching Pedidos:', error);
      }
    };

    fetchPedidos();

    const intervalId = setInterval(fetchPedidos, 5000);

    return () => clearInterval(intervalId);
  }, []);






  

  if (!producto) return <div>
    <div class="pyramid-loader">
  <div class="wrapper">
    <span class="side side1"></span>
    <span class="side side2"></span>
    <span class="side side3"></span>
    <span class="side side4"></span>
    <span class="shadow"></span>
  </div>  
</div>

  </div>;



  //Para seleccionar TaLLA
  const handleTallaChange = (talla) => {
    setSelectedTalla(talla);
    // Puedes hacer algo con la talla seleccionada aquí
    console.log('Talla seleccionada:', talla);
  };

  const handleColorChange = (color) => {
    setSelectedColor(color);
    // Puedes hacer algo con la Color seleccionada aquí
    console.log('Color seleccionada:', color);
  };

  const handleCantidadChange = (e) => {
    const inputValue = parseInt(e.target.value);
    if (!isNaN(inputValue) && inputValue >= 0 && inputValue <= producto.stock) {
      setCantidad(inputValue.toString()); // Convertir a cadena para reflejar el valor ingresado correctamente
    }
  };

  
  const handleAgregarAlCarrito = async () => {
    if (!producto || !usuarios || !selectedColor || !selectedTalla || !cantidad) {
      setErrorMessage('Error: Completa todos los campos');
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
      return;
    }

    if (usuarios.tipo !== 'cliente') {
      navigate('/login');
      return;
    }

    const nuevoProducto = {
      id: productoId,
      nombre: producto.nombre,
      descripcion: producto.descripcion,
      imagen: producto.imagenes[0],
      precio: producto.precio,
      color: selectedColor,
      talla: selectedTalla,
      cantidad: parseInt(cantidad),
    };

    // Verificar si el producto ya existe en pedidosC con la misma talla y color
    const existingProduct = pedidosC.find(
      (item) => item.productos.id === productoId && item.productos.color === selectedColor && item.productos.talla === selectedTalla
    );

    if (existingProduct) {
      // Actualizar la cantidad del producto existente
      const updatedProducts = pedidosC.map((item) => {
        if (item.productos.id === productoId && item.productos.color === selectedColor && item.productos.talla === selectedTalla) {
          return { ...item, cantidad: item.productoscantidad + parseInt(cantidad) };
        }
        return item;
      });

      setPedidosC(updatedProducts);
    } else {
      // Agregar nuevo producto al carrito
      const updatedFormData = {
        ...formData,
        productos: [...formData.productos, nuevoProducto],
        total: formData.total + nuevoProducto.precio * nuevoProducto.cantidad,
      };

      try {
        const response = await axios.post('https://ehr-mall-server.onrender.com/api/pedidos', updatedFormData);
        console.log('Producto agregado al carrito:', response.data);
        setFormData(updatedFormData);
      } catch (error) {
        console.error('Error al agregar producto al carrito:', error);
        setErrorMessage('Error: No se pudo agregar el producto al carrito');
      }
    }
  };




  return (
    <>
      <ArrowLeftOutlined className='icono-retroceso' onClick={() => navigate(-1)} />

      <div className='estiloContenedor-R1'>
        <div className='head-form'>
          <h1 className='detalleTitulo'>{producto.nombre}</h1>
          <Slider dots infinite speed={500} slidesToShow={1} slidesToScroll={1}>
            {producto.imagenes.map((foto, index) => (
              <div key={index}>
                <img src={foto} alt={`Foto ${index + 1} de ${producto.nombre}`} className='detalleI' />
              </div>
            ))}
          </Slider>
        </div>

        <p className='detalleTiempo'>Tiempo de entrega: {producto.tiempoEntrega} día(s)</p>

        <ColorList 
        colores={producto.color} 
        selectedColor={selectedColor}
        onColorChange={handleColorChange}
        
        />
        <TallaLista 
        tallas={producto.talla}
        selectedTalla={selectedTalla}
        onTallaChange={handleTallaChange}
        
       
        />

        {producto.descuento >   0 ? (
          <>
            <p className='precio'>
              Antes: <span className='colorR'>${producto.precio}</span>
            </p>
            <p className='precio'>
              Ahora: <span className='descripciones-1'>${((producto.precio * (100 - producto.descuento)) / 100).toFixed(2)}</span>
            </p>
            <p>Descuento: <span className='colorV'>{producto.descuento}%</span></p>
          </>
        ) : (
          <p className='precio'>Precio: ${producto.precio}</p>
        )}

        <label htmlFor='cantidad'  className='cantidadd'>Cantidad: </label>
        <input
          type='number'
          id='cantidad'
          class="form-input-1" 
          min='1'
          placeholder={`Cantidad disponible: ${producto.stock || 0}`}
          value={cantidad}
          onChange={handleCantidadChange}
        />

        <br></br><br></br>

        <div className='producto-info'>
          <SeccionDesplegable titulo='Detalles'>
            <p className='descripciones'>{producto.descripcion}</p>
          </SeccionDesplegable>
        </div>
       
        <Botoness onClick={handleAgregarAlCarrito}>Agregar</Botoness>
        {errorMessage && (
          <h3 style={{ color: "red" }}>{errorMessage}</h3>
        )}
     


       
      </div>
    </>
  );
};

export default DetallesProducto;
