import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import {BotonR,BotonV,BtnCancelar,BtnGuardar}  from '../Usuarios/boton';
import CustomTable from '../Usuarios/tablaUsuarios';
import { Link } from "react-router-dom";

const QuienesSomos=()=>{
  const navigate = useNavigate();
  const [info, setInfo] = useState(null);
  const [editar, setEditar] = useState(false);
  const [datosFormulario, setDatosFormulario] = useState({
    titulo: '',
    descripcion: '',
    valores: '',
    historia: '',
  });

  //Cargamos la consulta
  useEffect(() => {
    cargarQuienesSomos();
  }, []);

  //Hacemos la consulta de quienes somos
  const cargarQuienesSomos = async () => {
    try {
      const response = await axios.get('https://ehr-mall-server.onrender.com/api/quienesSomos');
      if (response.data.length > 0) {
        const data = response.data[0];
        setInfo(data);
       
        //Formateamos
        setDatosFormulario({
          titulo: data.titulo,
          descripcion: data.descripcion,
          valores:   data.valores.join(', ') ,
          historia: data.historia,
        });
        
      }
    } catch (error) {
      console.error("Error al cargar Quiénes Somos", error);
    }
  };

  //Insertamos ala base de datos
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDatosFormulario({
      ...datosFormulario,
      [name]: value,
    });
  };

  //Insertamos ala base de datos
  const handleSubmit = async (e) => {
    e.preventDefault();
    const valoresArray = datosFormulario.valores.split(', ').map(v => v.trim());
    const datosParaEnviar = {
      ...datosFormulario,
      valores: valoresArray.join(', ') // Convertir de nuevo a cadena separada por comas para enviar al servidor
    };
    const apiUrl = info && info._id ? `https://ehr-mall-server.onrender.com/api/quienesSomos/${info._id}` : 'https://ehr-mall-server.onrender.com/api/quienesSomos';
    const method = info && info._id ? 'patch' : 'post';

    try {
      const response = await axios[method](apiUrl, datosParaEnviar);
      if (response.status === 200 || response.status === 201) {
        alert('Información actualizada con éxito');
        cargarQuienesSomos(); // Recargar la información actualizada
        setEditar(false); // Salir del modo de edición
      } else {
        alert('La actualización no se completó. Por favor, inténtalo de nuevo.');
      }
    } catch (error) {
      console.error("Error al actualizar Quiénes Somos: ", error);
      alert('Ocurrió un error al intentar guardar los cambios. Por favor, verifica la consola para más detalles.');
    }
  };


    //Diseño de la tabla
    const columns = [
        { title: 'Titulo', dataIndex: 'titulo', key: 'titulo' },
        { title: 'Descripcion', dataIndex: 'descripcion', key: 'descripcion' },
        { title: 'Valores', dataIndex: 'valores', key: 'valores' },
        { title: 'Historia', dataIndex: 'historia', key: 'historia' },
        {
          title: 'Acciones',
          key: 'acciones',
          className:"titulo-tbl-1 ",
          render: () => (
            <div>             
              <BotonV title={"SobreNosotros"}onClick={() => setEditar(true)}>Editar</BotonV>
             
            </div>
          ),
        },
      ];
    
       
  // Datos a mostrar en la tabla (debe ser un arreglo con un objeto)
  const dataNosotros = info ? [info]  : [];




  return (
    <div >
      {!editar ? (
        <>
        <center><div className="floatbox-1">
        <div className="item-1">
          <div className="content">
            <div className="Titulo">
              <h5 className='titulo-2'>Quiénes Somos</h5>
            </div>
          <center> <CustomTable data={dataNosotros} columns={columns} tableSize="large"/>  
          </center> 
          </div>
        </div>
        </div></center>        
          
        </>
      ) : (
        <div className="estiloContenedor">
        <Link to="/admin">
          <div className="iconoCasa">&#127968;</div>
        </Link>
        <h1 className="estiloTitulo">Sobre Nosotros</h1>
  
        <form onSubmit={handleSubmit} >

          <div className="estiloCampo">
            <label htmlFor="titulo" className="estiloEtiqueta">
              📔 Título
            </label>
            <input
              type="text"
              name="titulo"
              className="estiloInput"
              value={datosFormulario.titulo}
              onChange={handleInputChange}
            />
          </div>
  
          <div className="estiloCampo">
            <label htmlFor="descripcion" className="estiloEtiqueta">
              📝 Descripción
            </label>
            <textarea
              name="descripcion"
              className="estiloInput"
              value={datosFormulario.descripcion}
              onChange={handleInputChange}
            ></textarea>
          </div>
  
          <div className="estiloCampo">
            <label htmlFor="valores" className="estiloEtiqueta">
              📊 Valores (separados por coma)
            </label>
            <input
              type="text"
              name="valores"
              className="estiloInput"
              value={datosFormulario.valores}
              onChange={handleInputChange}
            />
          </div>
  
          <div className="estiloCampo">
            <label htmlFor="historia" className="estiloEtiqueta">
              📖 Historia
            </label>
            <textarea
              name="historia"
              className="estiloInput"
              value={datosFormulario.historia}
              onChange={handleInputChange}
            ></textarea>
          </div>
          <BtnGuardar  type="submit">Guardar Cambios</BtnGuardar>
          <BtnCancelar title={"Nosotros"} onClick={() => setEditar(false)}>Cancelar</BtnCancelar>    
        </form>
      </div>
      )}
     </div>
  );
}



const MisionVision=()=>{
  const navigate = useNavigate();
  const [info, setInfo] = useState(null);
  const [editar, setEditar] = useState(false);
  const [datosFormulario, setDatosFormulario] = useState({
    mision: '',
    vision: '',
  });

  //Cargamos la consulta
  useEffect(() => {
    misionVision();
  }, []);

  //Hacemos la consulta de quienes somos
  const misionVision = async () => {
    try {
      const response = await axios.get('https://ehr-mall-server.onrender.com/api/misionVision');
      if (response.data.length > 0) {
        const data = response.data[0];
        setInfo(data);
       
        //Formateamos
        setDatosFormulario({
          mision: data.mision,
          vision: data.vision,
          
        });
        
      }
    } catch (error) {
      console.error("Error al cargar Politicas", error);
    }
  };

  //Insertamos ala base de datos
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDatosFormulario({
      ...datosFormulario,
      [name]: value,
    });
  };

  //Insertamos ala base de datos
  const handleSubmit = async (e) => {
    e.preventDefault();
    const datosParaEnviar = {
      ...datosFormulario,
    };
    const apiUrl = info && info._id ? `https://ehr-mall-server.onrender.com/api/misionVision/${info._id}` : 'https://ehr-mall-server.onrender.com/api/misionVision';
    const method = info && info._id ? 'patch' : 'post';

    try {
      const response = await axios[method](apiUrl, datosParaEnviar);
      if (response.status === 200 || response.status === 201) {
        alert('Información actualizada con éxito');
        misionVision(); // Recargar la información actualizada
        setEditar(false); // Salir del modo de edición
      } else {
        alert('La actualización no se completó. Por favor, inténtalo de nuevo.');
      }
    } catch (error) {
      console.error("Error al actualizar Mision y Vision: ", error);
      alert('Ocurrió un error al intentar guardar los cambios. Por favor, verifica la consola para más detalles.');
    }
  };


    //Diseño de la tabla
    const columns = [
        { title: 'Mision', dataIndex: 'mision', key: 'mision' },
        { title: 'Vision', dataIndex: 'vision', key: 'vision' },
       
        {
          title: 'Acciones',
          key: 'acciones',
          className:"titulo-tbl-1 ",
          render: () => (
            <div>             
              <BotonV title={"MisionVision"}onClick={() => setEditar(true)}>Editar</BotonV>
             
            </div>
          ),
        },
      ];
    
       
  // Datos a mostrar en la tabla (debe ser un arreglo con un objeto)
  const dataNosotros = info ? [info]  : [];


  return (
    <div >
      {!editar ? (
        <>
        <center><div className="floatbox-1">
        <div className="item-1">
          <div className="content">
            <div className="Titulo">
              <h5 className='titulo-2'>Mision Y Vision</h5>
            </div>
          <center> <CustomTable data={dataNosotros} columns={columns} tableSize="large"/>  
          </center> 
          </div>
        </div>
        </div></center>        
          
        </>
      ) : (
        <div className="estiloContenedor">
        <Link to="/admin">
          <div className="iconoCasa">&#127968;</div>
        </Link>
        <h1 className="estiloTitulo">Mision y Vision</h1>
  
        <form onSubmit={handleSubmit} autoComplete="off">

          <div className="estiloCampo">
            <label htmlFor="mision" className="estiloEtiqueta">
             📝 Mision
            </label>
            <textarea
              type="text"
              name="mision"
              className="estiloInput"
              value={datosFormulario.mision}
              onChange={handleInputChange}
            />
          </div>
  

          <div className="estiloCampo">
            <label htmlFor="vision" className="estiloEtiqueta">
             📝 Vision
            </label>
            <textarea
              type="text"
              name="vision"
              className="estiloInput"
              value={datosFormulario.vision}
              onChange={handleInputChange}
            />
          </div>
  
         
          <BtnGuardar  type="submit">Guardar Cambios</BtnGuardar>
          <BtnCancelar title={"Mision Y Vision"} onClick={() => setEditar(false)}>Cancelar</BtnCancelar>    
        </form>
      </div>
      )}
     </div>
  );
}

const PoliticaPrivacidad=()=>{

  const navigate = useNavigate();
  const [info, setInfo] = useState(null);
  const [editar, setEditar] = useState(false);
  const [datosFormulario, setDatosFormulario] = useState({
    titulo: '',
    descripcion: '',
  });

  //Cargamos la consulta
  useEffect(() => {
    politicas();
  }, []);

  //Hacemos la consulta de quienes somos
  const politicas = async () => {
    try {
      const response = await axios.get('https://ehr-mall-server.onrender.com/api/politicas');
      if (response.data.length > 0) {
        const data = response.data[0];
        setInfo(data);
        setDatosFormulario({
          titulo: data.titulo,
          descripcion: data.descripcion,
          
        });
        
      }
    } catch (error) {
      console.error("Error al cargar titulo y Vision", error);
    }
  };

  //Insertamos ala base de datos
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDatosFormulario({
      ...datosFormulario,
      [name]: value,
    });
  };

  //Insertamos ala base de datos
  const handleSubmit = async (e) => {
    e.preventDefault();
    const datosParaEnviar = {
      ...datosFormulario,
    };
    const apiUrl = info && info._id ? `https://ehr-mall-server.onrender.com/api/politicas/${info._id}` : 'https://ehr-mall-server.onrender.com/api/politicas';
    const method = info && info._id ? 'patch' : 'post';

    try {
      const response = await axios[method](apiUrl, datosParaEnviar);
      if (response.status === 200 || response.status === 201) {
        alert('Información actualizada con éxito');
        politicas(); // Recargar la información actualizada
        setEditar(false); // Salir del modo de edición
      } else {
        alert('La actualización no se completó. Por favor, inténtalo de nuevo.');
      }
    } catch (error) {
      console.error("Error al actualizar Politicas: ", error);
      alert('Ocurrió un error al intentar guardar los cambios. Por favor, verifica la consola para más detalles.');
    }
  };


    //Diseño de la tabla
    const columns = [
        { title: 'Titulo', dataIndex: 'titulo', key: 'titulo' },
        { title: 'Descripcion', dataIndex: 'descripcion', key: 'descripcion' },
       
        {
          title: 'Acciones',
          key: 'acciones',
          className:"titulo-tbl-1 ",
          render: () => (
            <div>             
              <BotonV title={"Politicas"}onClick={() => setEditar(true)}>Editar</BotonV>
             
            </div>
          ),
        },
      ];
    
       
  // Datos a mostrar en la tabla (debe ser un arreglo con un objeto)
  const dataNosotros = info ? [info]  : [];


  return (
    <div >
      {!editar ? (
        <>
        <center><div className="floatbox-1">
        <div className="item-1">
          <div className="content">
            <div className="Titulo">
              <h5 className='titulo-2'>Mision Y Vision</h5>
            </div>
          <center> <CustomTable data={dataNosotros} columns={columns} tableSize="large"/>  
          </center> 
          </div>
        </div>
        </div></center>        
          
        </>
      ) : (
        <div className="estiloContenedor">
        <Link to="/admin">
          <div className="iconoCasa">&#127968;</div>
        </Link>
        <h1 className="estiloTitulo">Politicas</h1>
  
        <form onSubmit={handleSubmit} autoComplete="off">

          <div className="estiloCampo">
            <label htmlFor="titulo" className="estiloEtiqueta">
             📝Titulo
            </label>
            <textarea
              type="text"
              name="titulo"
              className="estiloInput"
              value={datosFormulario.titulo}
              onChange={handleInputChange}
            />
          </div>
  

          <div className="estiloCampo">
            <label htmlFor="descripcion" className="estiloEtiqueta">
             📝 Descripcion
            </label>
            <textarea
              type="text"
              name="descripcion"
              className="estiloInput"
              value={datosFormulario.descripcion}
              onChange={handleInputChange}
            />
          </div>
  
         
          <BtnGuardar  type="submit">Guardar Cambios</BtnGuardar>
          <BtnCancelar title={"Politicas"} onClick={() => setEditar(false)}>Cancelar</BtnCancelar>    
        </form>
      </div>
      )}
     </div>
  );
}

export {QuienesSomos,MisionVision,PoliticaPrivacidad}