import React, { useEffect, useState } from 'react';
import CustomTable from './tablaUsuarios';
import "./usuarios.css";
import {BotonR,BotonV,BtnCancelar,BtnGuardar}  from './boton';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios"; // Asegúrate de importar axios si no lo has hecho aún
import { Link } from "react-router-dom";
import bcrypt from "bcryptjs"; // Importa la librería bcryptjs para encriptar la contraseña
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Swal from 'sweetalert2';




const Usuarios = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [usuarioEditar, setUsuarioEditar] = useState(null);
  const [contador, setContador] = useState(0);
  const [contadorClient, setContadorClient] = useState(0);


  // Consultar usuarios al cargar el componente
  useEffect(() => {
    cargarUsuarios();
  }, []);


   // Función para cargar usuarios
   const cargarUsuarios = () => {
    fetch('https://ehr-mall-server.onrender.com/api/usuarios')
      .then(response => response.json())
      .then(data => setUsuarios(data))
      .catch(error => console.error("Error al cargar los usuarios", error));
  };



  // Efecto para actualizar los contadores cuando cambia la lista de usuarios
  useEffect(() => {
    const administradores = usuarios.filter(usuario => (usuario.tipo || []).includes('administrador'));
    const clientes = usuarios.filter(usuario => (usuario.tipo || []).includes('cliente'));

    setContador(administradores.length);
    setContadorClient(clientes.length);
  }, [usuarios]);

 // Eliminar usuario por ID
 const eliminarUsuario = (id) => {
  cargarUsuarios();
  fetch(`https://ehr-mall-server.onrender.com/api/usuarios/${id}`, {
    method: 'DELETE',
    
  })
   
    .then(response => {
      if (response.ok) {
        console.log("Eliminado correctamente");
        window.alert("El usuario ha sido eliminado correctamente."); // Mostrar alerta de eliminación exitosa
        cargarUsuarios(); // Volver a cargar la lista de usuarios después de la eliminación
      } else {
        throw new Error('Failed to delete the user');
      }
    })
    .catch(error => console.error("Error deleting user:", error));
    cargarUsuarios();
};

  
  


  // Mostrar formulario de edición con datos del usuario
  const mostrarFormularioEdicion = (usuario) => {
    setUsuarioEditar(usuario); // Establecer usuario para editar
  };

  // Actualizar usuario editado
  const editarUsuario = (e) => {
    e.preventDefault();
    const { _id, ...datosParaActualizar } = usuarioEditar;
    fetch(`https://ehr-mall-server.onrender.com/api/usuarios/${_id}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(datosParaActualizar),
    })
      .then(response => {
        if (response.ok) {
          setUsuarios(prevUsers =>
            prevUsers.map(usuario =>
              usuario._id === _id ? { ...usuario, ...datosParaActualizar } : usuario
            )
          );

          //alert
          Swal.fire({
            title: 'Guardado',
            text: 'Los cambios se han guardado correctamente.',
            icon: 'success',
            customClass: {
              container: 'my-swal-container',
              title: 'my-swal-title',
              content: 'my-swal-content'
            }
          })




          setUsuarioEditar(null); // Cerrar formulario de edición
        } else {
          throw new Error('Failed to update the user');
        }
      })
      .catch(error => console.error("Error updating user", error));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUsuarioEditar(prevUser => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const columns = [
    { title: 'Nombre', dataIndex: 'nombre', key: 'nombre' },
    { title: 'ApellidoP', dataIndex: 'apellidoP', key: 'apellidoP' },
    { title: 'ApellidoM', dataIndex: 'apellidoM', key: 'apellidoM' },
    { title: 'Username', dataIndex: 'username', key: 'username' },
    { title: 'Teléfono', dataIndex: 'telefono', key: 'telefono' },
    { title: 'Correo', dataIndex: 'correo', key: 'correo' },
    {
      title: 'Acciones',
      key: 'acciones',
      className:"titulo-tbl ",
      render: (usuario) => (
        <div>
          {/* <button onClick={() => eliminarUsuario(usuario._id)}>Eliminar</button> */}
          <BotonR title={"usuario"} onClick={()=>eliminarUsuario(usuario._id)}>Eliminar</BotonR>
          
          <BotonV onClick={() => mostrarFormularioEdicion(usuario)}>Editar</BotonV>
        </div>
      ),
    },
  ];

  const dataCliente = usuarios
    .filter(usuario => (usuario.tipo || []).includes('cliente'))
    .map(usuario => ({
    
      ...usuario,
    }));

  const dataAdministradores = usuarios
    .filter(usuario => (usuario.tipo || []).includes('administrador'))
    .map(usuario => ({
     
      ...usuario,
    }));

  return (





    <>
    <br></br>
      <div className="floatbox">
        <div className="item">
          <div className="content">
            <div className="Titulo">
              <h5 className='titulo-2'>Lista de Administradores</h5>
            </div>
            <p className='contador'>Total de administradores: {contador}</p>
            <CustomTable data={dataAdministradores} columns={columns} tableSize="middle"/>
          </div>
        </div>

        <div className="item">
          <div className="content">
            <div className="Titulo">
              <h5 className='titulo-2'>Lista de Clientes</h5>
            </div>
            <p className='contador'>Total de clientes: {contadorClient}</p>
            <CustomTable data={dataCliente} columns={columns} tableSize="middle" />
          </div>
        </div>
        </div>



        {/* Formulario de edición */}
         {usuarioEditar && (
           <div className="form-container">
           <div className="title">
            <h5>Editar Usuario</h5>
          </div>
          <form onSubmit={editarUsuario} className="my-swal-content">
            <div className="mb-3">
              <label htmlFor="nombre" className="form-label">Nombre:</label>
              <input type="text" className="form-input" id="nombre" name="nombre" value={usuarioEditar.nombre} onChange={handleInputChange} />
            </div>
            <div className="mb-3">
              <label htmlFor="apellidoP" className="form-label">Apellido Paterno:</label>
              <input type="text" className="form-input" id="apellidoP" name="apellidoP" value={usuarioEditar.apellidoP} onChange={handleInputChange} />
            </div>
            <div className="mb-3">
              <label htmlFor="apellidoM" className="form-label">Apellido Materno:</label>
              <input type="text" className="form-input" id="apellidoM" name="apellidoM" value={usuarioEditar.apellidoM} onChange={handleInputChange} />
            </div>
            <div className="mb-3">
              <label htmlFor="username" className="form-label">Username:</label>
              <input type="text" className="form-input" id="username" name="username" value={usuarioEditar.username} onChange={handleInputChange} />
            </div>
            <div className="mb-3">
              <label htmlFor="telefono" className="form-label">Teléfono:</label>
              <input type="text" className="form-input" id="telefono" name="telefono" value={usuarioEditar.telefono} onChange={handleInputChange} />
            </div>
            <div className="mb-3">
              <label htmlFor="correo" className="form-label">Correo:</label>
              <input type="email" className="form-input" id="correo" name="correo" value={usuarioEditar.correo} onChange={handleInputChange} />
            </div>
            <BtnGuardar >Guardar Cambios</BtnGuardar>
            <BtnCancelar title={"usuario"} onClick={() => setUsuarioEditar(null)}>Cancelar</BtnCancelar>
           
          </form>
          
        </div>
                 
      )}
        
      
    </>
  );
};



const InsertarUsuario=()=>{
  //DEFINOSMOS CONSTANTES
  const navigate=useNavigate();
  const [forData, setFormData]=useState({    
     nombre: "",
     username:" ",
     apellidoP:"",
     apellidoM:"",
     correo:"",
     telefono:"",
     tipo:"administrador",
     id_pregunta:"",
     respuesta:"",
     password:"",
     direccion: {
      calle: '',
      localidad: '',
      municipio: ' ',
      estado: '',
      pais: '',
      codigo_postal: '',
      detalles:'',

     }
  });
  const [section, setSection] = useState(0);
  const [confirmarP, setconfirmarP] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [usuarios, setUsuarios] = useState([]);
  const [preguntas, setPreguntas] = useState([]);

  //funcion
  useEffect(() => {
    //Realizamos la consulta para usuarios
    axios.get("https://ehr-mall-server.onrender.com/api/usuarios")
    .then(response => {
      // Almacenar los datos de los usuarios en el estado
      setUsuarios(response.data);
    })
    .catch(error => console.error("Error al cargar los usuarios: ", error));
  }, []);

  useEffect(() => {
    //Para hacer consulta pregunta
    fetch("https://ehr-mall-server.onrender.com/api/pregunta")
      .then((response) => response.json())
      .then((data) => setPreguntas(data))
      .catch((error) => console.error("Error al cargar las preguntas ", error));
  }, []);




  const hadleChange = (e)=>{
    const {name,value}= e.target;
    //insertamos los datos
    setFormData({
      ...forData,
      [name]:value,
    });

  };
  //Conprobar que las contraseñas considan
  const handleSubmit = async(e)=>{
    e.preventDefault();
    if(forData.password != confirmarP){
      setErrorMessage('Las contraseñas no coinciden');
      return;
    }
  

  //Encrptamos la contraseña a insertar
  const hashePassword = await bcrypt.hash(forData.password, 10);
  //creamos un objeto con la contraseña encriptado
  const formDataPassworEncryptado={
    ...forData,
    password: hashePassword,
  };
  //VERIFICAMOS si el correo a insertar no se encuenta en la base de datos
  try{
    const usuarioCorreo = usuarios.find(usuario=>usuario.correo===forData.correo)
    if(usuarioCorreo){
      setErrorMessage('Error el correo '+forData.correo + ' ya esta registrado.');
    }else{
      await axios.post(
        "https://ehr-mall-server.onrender.com/api/usuarios",
        formDataPassworEncryptado

      );
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Usuario creado correctamente",
        showConfirmButton: false,
        timer: 1500
      });
      //nos dirigimos alas lista de user
      navigate("/admin/usuarios")

    }

  }catch (error){
    
  Swal.fire({
  position: "top-end",
  icon: "error",
  title: "Error a registrar",
  showConfirmButton: false,
  timer: 1500
});

  }

  }




  return(
    <>
    <br></br>
      <div className="estiloContenedor">
        <Link to="/admin">
          <br></br>
          <div className="incon_house">&#127968;</div>
        </Link>
        
        <h1 className="estiloTitulo">Registro</h1>
        <form onSubmit={handleSubmit} autoComplete="off">
          <div className="estiloCampo">
            <label htmlFor="nombre" className="estiloEtiqueta">
              &#128100; Nombre
            </label>
            <input
              autoComplete="off"
              type="text"
              placeholder="Nombre"
              name="nombre"
              className="estiloInput"
              value={forData.nombre}
              onChange={hadleChange}
              title="Solo se aceptan letras"
              required
              maxLength={25}
              pattern="^[a-zA-Z\s]*$"
            />
            <br />
            <br />
          </div>

          <div className="estiloCampo">
            <label
              htmlFor="apellidoP"
              className="estiloEtiqueta"
            >
              &#128100; Apellido Paterno:
            </label>
            <input
              autoComplete="off"
              type="text"
              maxLength={25}
              pattern="[a-zA-Z]*"
              placeholder="Apellido Paterno"
              name="apellidoP"
              className="estiloInput"
              value={forData.apellidoP}
              onChange={hadleChange}
              title="Solo se aceptan letras"
              required
            />
          </div>

          <div className="estiloCampo">
            <label
              htmlFor="apellidoM"
              className="estiloEtiqueta"
            >
              &#128100; Apellido Materno:
            </label>
            <input
              autoComplete="off"
              type="text"
              maxLength={25}
              pattern="[a-zA-Z]*"
              placeholder="Apellido Materno"
              name="apellidoM"
              className="estiloInput"
              value={forData.apellidoM}
              onChange={hadleChange}
              title="Solo se aceptan letras"
              required
            />
          </div>

         
          <div className="estiloCampo">
            <label htmlFor="correo" className="estiloEtiqueta">
              &#9993; Correo Electrónico:
            </label>
            <input
              autoComplete="off"
              type="email"
              name="correo"
              placeholder="Correo electrónico"
              className="estiloInput"
              value={forData.correo}
              onChange={hadleChange}
              required
              title="Formato invalido"
              minLength={8}
              maxLength={30} // Ajustado a un valor razonable, puede ser mayor si lo deseas
              pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$" // Expresión regular para validar correo electrónico
            />
          </div>

          <div className="estiloCampo">
            <label htmlFor="username" className="estiloEtiqueta">
              &#128100; Usuario:
            </label>
            <input
              autoComplete="off"
              type="text"
              name="username"
              placeholder="Username"
              className="estiloInput"
              value={forData.username}
              onChange={hadleChange}
              maxLength={15}
              
              minLength={4}
              title="Solo se acepta numeros y letras"
              required
            />
          </div>

          <div className="estiloCampo">
            <label htmlFor="telefono" className="estiloEtiqueta">
              &#128222; Teléfono:
            </label>
            <input
              autoComplete="off"
              type="tel"
              name="telefono"
              placeholder="Teléfono"
              className="estiloInput"
              value={forData.telefono}
              onChange={hadleChange}
              required
              maxLength={10}
              minLength={10}
              title="solo se aceptan numeros"
              pattern="[0-9]*"
            />
          </div>

          <div className="estiloCampo">
            <label htmlFor="password" className="estiloEtiqueta">
              &#128274; Contraseña:
            </label>
            <input
              autoComplete="off"
              type="password"
              name="password"
             
              className="estiloInput"
              value={forData.password}
              onChange={hadleChange}
              minLength={8}
             
              placeholder="Ingrese una contraseña segura"
    
              required
            />
          </div>

          <div className="estiloCampo">
            <label htmlFor="confirmarPassword" className="estiloEtiqueta">
              &#128274; Confirmar Contraseña:
            </label>
            <input
              autoComplete="off"
              type="password"
              name="confirmarPassword"
              placeholder="Confirmar Contraseña"
              className="estiloInput"
              minLength={8}
              value={confirmarP} 
              onChange={(e) => setconfirmarP(e.target.value)}
              required
              
            />
          </div>

          <div className="estiloCampo">
            <label htmlFor="id_pregunta" className="estiloEtiqueta">
              Selecciona una pregunta:
            </label>
            <select
              id="id_pregunta"
              name="id_pregunta"
              className="estiloInput"
              value={forData.id_pregunta}
              onChange={hadleChange}
              required
            >
              <option value="">Seleccione una pregunta</option>
              {preguntas.map((pregunta) => (
                <option key={pregunta._id} value={pregunta._id}>
                  {pregunta.pregunta}
                </option>
              ))}
            </select>
          </div>
          
          <div className="estiloCampo">
            <label
              htmlFor="datos_cliente.pregunta.respuesta"
              className="estiloEtiqueta"
            >
              &#128274; Respuesta:
            </label>
            <input
              autoComplete="off"
              type="text"
              name="respuesta"
              placeholder="respuesta"
              className="estiloInput"
              value={forData.respuesta}
              onChange={hadleChange}
              minLength={4}
              maxLength={12}
              required
            />
          </div>

          <br />
          <br />
          <BtnGuardar >Registrar</BtnGuardar>

          

          <br />
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        </form>
      </div>
    
    
    
    </>
  )


}

export {Usuarios, InsertarUsuario};
