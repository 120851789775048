import React from "react";
import { FaSearch } from 'react-icons/fa';
import './busqueda.css'; // Importa tu archivo de estilos CSS aquí

const SearchBar = () => {
    return (
        <div className="busqueda">
            <input type="text" className="search-input" placeholder="Buscar en EHR MALL" />
            <button className="search-button">
                <FaSearch />
            </button>
        </div>
    );
}

export default SearchBar;
