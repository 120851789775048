import React from "react";
import "./subcategoria.css"; // Ajusta la ruta según la ubicación de tu archivo CSS
import {  FaSpotify } from "react-icons/fa"; // Importar el ícono de Spotify desde React Icons
import { RiShoppingBag3Line } from "react-icons/ri"; // Importar el ícono de la bolsa de compras desde React Icons


//Agregro de colores
const colors = [
  "#FF5733", // Naranja
  "#33FFA8", // Verde menta
  "#337AFF", // Azul claro
  "#FF33DD", // Rosa fuerte
  "#FFFF33", // Amarillo brillante
  "#3366FF", // Azul marino
  "#33FFFF", // Turquesa
  "#FF33CC", // Rosa claro
  "#33FF33", // Verde claro
  "#FF6633", // Naranja oscuro
  "#9932CC", // Púrpura
  "#FFA07A", // Salmón claro
  "#4682B4", // Azul acero
  "#7FFF00", // Verde chartreuse
  "#8A2BE2", // Azul violeta
  "#00FF7F", // Verde primavera
  "#20B2AA", // Verde mar
  "#FA8072", // Salmón claro
  "#4169E1", // Azul real
  "#FFD700"  // Oro
  // Agrega más colores aquí si lo deseas
];


//Arreglo de inconos

const Avatar = (props) => {
  const { tooltipText, onClick } = props; 


  const randomColor = colors[Math.floor(Math.random() * colors.length)];

  return (
    <ul className="example-2">
      <li className="icon-content"  onClick={onClick}>
        <a
          data-social="spotify"
          aria-label="Spotify"
          className="avatar-link"
          style={{ backgroundColor: randomColor }}
        >
          <div className="filled"></div>
          <div className="icon-container">
          <RiShoppingBag3Line size={100} color="black" />
          
          </div>
        </a>
        <div className="tooltip">{tooltipText}</div>{" "}
   
      </li>
     
    </ul>
  );
};

export default Avatar;
