import CustomTable from "../Usuarios/tablaUsuarios";
import React, { useEffect, useState } from 'react';
import {BotonR,BotonV,BtnCancelar,BtnGuardar}  from '../Usuarios/boton';
import axios from "axios"; // Asegúrate de importar axios si no lo has hecho aún
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';


const ListaCategoria=()=>{
    const [categoria, setCategoria] = useState([]);
    const [contador, setContador] = useState(0);

    //consulta de categorias
    useEffect(() => {
        cargarCategoria();
      }, []);

        // Función para cargar Categoria
   const cargarCategoria = () => {
    fetch('https://ehr-mall-server.onrender.com/categorias')
      .then(response => response.json())
      .then(data => setCategoria(data))
      .catch(error => console.error("Error al cargar los Categoria", error));
  };

  
  // Efecto para actualizar los contadores cuando cambia la lista de categoria
  useEffect(() => {
    // Calcular el total general de categoria
    const totalcategoria = categoria.length;
    
    // Actualizar el contador total de categoria
    setContador(totalcategoria);
  }, [categoria]);

  
 // Eliminar categoria por ID
 const eliminarCategoria = (id) => {
  cargarCategoria(); 
    fetch(`https://ehr-mall-server.onrender.com/categorias/${id}`, {
      method: 'DELETE',
    })
      .then(response => {
        if (response.ok) {
          console.log("Eliminado correctamente");
          window.alert("la categoria ha sido eliminado correctamente."); // Mostrar alerta de eliminación exitosa
          cargarCategoria(); // Volver a cargar la lista de categorias después de la eliminación
        } else {
          throw new Error('Failed to delete the categoria');
        }
      })
      .catch(error => console.error("Error deleting categoria:", error));
      cargarCategoria(); 
    };


  //Diseño de la tabla
  const columns = [
    { title: 'Categoria', dataIndex: 'categoria', key: 'categoria' },
    {
      title: 'Acciones',
      key: 'acciones',
      className:"titulo-tbl-1 ",
      render: (categoria) => (
        <div>
         
          <BotonR title={"categoria"} onClick={()=>eliminarCategoria(categoria._id)}>Eliminar</BotonR>
        </div>
      ),
    },
  ];

  //Mostrar datos
  const dataCategoria = categoria.map(categoria => ({
    ...categoria,
    key: categoria._id // Agregar una clave única para cada categoría
  }));
      
    return(
        <>
        
        <center><div className="floatbox-1">
        <div className="item-1">
          <div className="content">
            <div className="Titulo">
              <h5 className='titulo-2'>Lista de Categoria</h5>
            </div>
            <p className='contador'>Total de Categorias: {contador}</p>
          <center> <CustomTable data={dataCategoria} columns={columns} tableSize="small"/>
          </center> 
          </div>
        </div>
        </div></center>        
        </>
    )
}

const InsertarCategoria=()=>{
  //DEFINOSMOS CONSTANTES
  const navigate=useNavigate();
  const [forData, setFormData]=useState({    
    categoria:"",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [categorias, setCategoria] = useState([]);

  //funcion
  useEffect(() => {
    //Realizamos la consulta para usuarios
    axios.get("https://ehr-mall-server.onrender.com/categorias")
    .then(response => {
      // Almacenar los datos de las cetrgorias en el estado
      setCategoria(response.data);
    })
    .catch(error => console.error("Error al cargar las cetegorias: ", error));
  }, []);
  
  //Insertamos los datos ala base de datos
  const hadleChange = (e)=>{
    const {name,value}= e.target;
    //insertamos los datos
    setFormData({
      ...forData,
      [name]:value,
    });

  };

  const handleSubmit = async(e)=>{
    e.preventDefault();
    //VERIFICAMOS si la categoria a insertar no se encuenta en la base de datos
  try{
    const categoriaP = categorias.find(categoria=>categoria.categoria===forData.categoria)
    if(categoriaP){
      setErrorMessage('Error la categoria  '+forData.categoria + ' ya esta registrado.');
    }else{
      // Realizar la inserción en la base de datos
      await axios.post("https://ehr-mall-server.onrender.com/categorias", {
        categoria: forData.categoria  // Enviar el nombre de la categoría en el cuerpo de la solicitud
    });
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Categoria creado correctamente",
        showConfirmButton: false,
        timer: 1500
      });
      //nos dirigimos alas lista de user
      navigate("/admin/categoria")

    }

  }catch (error){
    
  Swal.fire({
  position: "top-end",
  icon: "error",
  title: "Error a registrar",
  showConfirmButton: false,
  timer: 1500
});
  }
  }





    return(
        <>
     
        <br></br>
      <div className="estiloContenedor">
        <Link to="/admin">
          <br></br>
          <div className="incon_house">&#127968;</div>
        </Link>
        <h1 className="estiloTitulo">Insertar categoria</h1>
        <form onSubmit={handleSubmit} autoComplete="off">
          <div className="estiloCampo">
            <label htmlFor="categoria" className="estiloEtiqueta">
              &#128100; Categoria
            </label>
            <input
              autoComplete="off"
              type="text"
              placeholder="categoria"
              name="categoria"
              className="estiloInput"
              value={forData.categoria}
              onChange={hadleChange}
              title="Solo se aceptan letras"
              required
              maxLength={100}
              // pattern="[a-zA-Z]*"
            />
            <br />
            <br />
          </div>
          <br />
          <br />
          <BtnGuardar >Registrar</BtnGuardar>

          <br />
          {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
        </form>
      </div>

      
        
        
        </>
    )
}

export {ListaCategoria, InsertarCategoria};